import React, { useEffect, useState } from "react";
import Input from "../../../../../ReusableComponents/Input";
import { Button } from "../../../../../Components/Helper";
import { getLocations } from "../../../../../apis/locations";
import {
  addService,
  updateService,
} from "../../../../../apis/services";
import Survey from "../../../../../assets/survey.svg";
import { GLOBAL_STYLES, LIMITS } from "../../../../../appConstants";
import Toggle from "react-toggle";
import {
  combineDateTime,
  isEmptyString,
  isSpecialCharExists,
  isValidGst,
  reduceLongName,
} from "../../../../../ReusableFunctions/reusableFunctions";
import Popup from "../../../../../ReusableFunctions/Popup";
import Loading from "../../../../../ReusableFunctions/Loader";
import Plus from "../../../../../assets/Plus.svg";
import AddLocation from "../../../../MyLocations/SubComponents/AddLocation";
import { ROUTES } from "../../../../../routes";
import { useNavigate } from "react-router-dom"
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import MainContentWrapper from "../../../../../Components/MainContentWrapper";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import ReadOnlyInput from "../../../../../ReusableComponents/ReadOnlyDatepicker";

const AddSurveyService = ({ aeroMeghService, service }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const isServiceToBeUpdated = service?.aeromegh_service_id;
  const dateFormatOption = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const [locations, setLocations] = useState([]);
  const DEVICES = ["RTK", "PPK", "LiDAR"];
  const DRONE_TYPES = ["Multi Rotors", "eVTOLS"];
  const [openAddLocationPopup, setOpenAddLocationPopup] = useState(false);
  const [survey_service, setSurveyService] = useState(
    isServiceToBeUpdated
      ? service
      : {
        name: aeroMeghService?.name,
        description: aeroMeghService?.description,
        aeromegh_service_id: aeroMeghService?.id,
        is_active: true,
        start_date: new Date(),
        parameters: {
          rate_per_sq_km: "",
          devices: [],
          drone_types: [],
          sgst: undefined,
          cgst: undefined,
        },
        locations: [],
      }
  );
  const handleOnChangeParameters = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'rate_per_sq_km') {
      if ((Number(value) > LIMITS.RATE_PER_SQ_KM)) return
    }

    setSurveyService((prev) => ({
      ...prev,
      parameters: { ...prev.parameters, [name]: value },
    }));
  };
  const handleOnChangeDevice = (e, device) => {
    if (e.target.checked) {
      //device is being added
      setSurveyService((prev) => ({
        ...prev,
        parameters: {
          ...prev.parameters,
          devices: [...prev.parameters.devices, device],
        },
      }));
    } else {
      setSurveyService((prev) => ({
        ...prev,
        parameters: {
          ...prev.parameters,
          devices: prev.parameters.devices.filter((dev) => dev !== device),
        },
      }));
    }
  };
  const handleOnChangeDroneTypes = (e, drone) => {
    if (e.target.checked) {
      //drone type is being added
      setSurveyService((prev) => ({
        ...prev,
        parameters: {
          ...prev.parameters,
          drone_types: [...prev.parameters.drone_types, drone],
        },
      }));
    } else {
      setSurveyService((prev) => ({
        ...prev,
        parameters: {
          ...prev.parameters,
          drone_types: prev.parameters.drone_types.filter((d) => d !== drone),
        },
      }));
    }
  };
  const handleOnChangeLocation = (e, location_id) => {
    if (e.target.checked) {
      //location is being added
      const addedLocation = locations.find((loc) => loc.id === location_id);
      setSurveyService((prev) => ({
        ...prev,
        locations: [...prev.locations, addedLocation],
      }));
    } else {
      setSurveyService((prev) => ({
        ...prev,
        locations: prev.locations.filter((loc) => loc.id !== location_id),
      }));
    }
  };
  const handleAddService = () => {
    Loading.set(true);
    addService(survey_service)
      .then((res) => {
        Loading.set(false);
        Popup.alert(
          "Survey Service",
          "Service added successfully",
          "SUCCESS",
          () => {
            navigate(ROUTES.MYSERVICES)
          }
        );
      })
      .catch((err) => {
        console.log(err);
        Loading.set(false);
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  };
  const handleUpdateService = () => {
    Loading.set(true);
    updateService(survey_service)
      .then((res) => {
        Loading.set(false);
        Popup.alert(
          "Survey Service",
          "Service updated successfully",
          "SUCCESS",
          () => {
            navigate(ROUTES.MYSERVICES)
          }
        );
      })
      .catch((err) => {
        console.log(err);
        Loading.set(false);
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  };
  const isAddServiceFormFilled = () => {
    return (
      (survey_service.parameters.drone_types.length > 0 ||
        survey_service.parameters.devices.length > 0) &&
      !(
        survey_service.parameters.rate_per_sq_km === "e" ||
        survey_service.parameters.rate_per_sq_km <= 0 ||
        survey_service.parameters.rate_per_sq_km.length === 0 ||
        survey_service.parameters.sgst < 0 ||
        survey_service.parameters.sgst === undefined ||
        survey_service.parameters.sgst === '' ||
        survey_service.parameters.cgst < 0 ||
        survey_service.parameters.cgst === undefined ||
        survey_service.parameters.cgst === ''
      ) &&
      survey_service.name.length > 0 &&
      locations.length > 0 &&
      !isEmptyString(survey_service.name)
    );
  };
  const onAddLocation = (location) => {
    setLocations([location, ...locations]);
  }

  useEffect(() => {
    Loading.set(true);
    setLoading(true);
    getLocations()
      .then((locations) => {
        setLocations(locations);
        setLoading(false);
        Loading.set(false);
      })
      .catch((err) => {
        Loading.set(false);
        setLoading(false);
        console.log(err);
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  }, []);
  useEffect(() => {
    if (service.aeromegh_service_id) setSurveyService(service);
  }, [service]);
  return (
    <>
      {/* Add location popup */}
      {openAddLocationPopup && (
        <AddLocation
          open={openAddLocationPopup}
          onClose={() => setOpenAddLocationPopup(false)}
          onAddLocation={onAddLocation}
        />
      )}
      <MainContentWrapper containerStyle={{overflow:"hidden"}} header={"My services"}>

        {/* <div style={{ marginBottom: "10px", color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Survey Service</div> */}
        <form
        noValidate
          onSubmit={(e) => {
            e.preventDefault();
            if (isAddServiceFormFilled()) {
              if (isServiceToBeUpdated) handleUpdateService();
              else handleAddService();
            }
          }}
          style={{
            width: "95%",
            height: "92%",
            margin: "auto auto",
            display: "flex",
            flexDirection: "column",
            border: `1px solid rgb(217 217 217 / 33%)`,
            borderRadius: "4px",
            background: "#FCFCFC",
            boxShadow: " 0px 0px 4px 0px #72727226",
            gap: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "20px 50px",
              marginBottom: "",
              borderBottom: "1px solid rgba(236, 239, 243, 1)"
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "15px", }}>
              <img src={Survey} style={{ width: "55px" }} />
              <div className="flex flex-col ">
                <span style={{ fontSize: "28px", color: "#1E1E1E", fontWeight: 500 }}>
                  Survey
                </span>
                <span style={{ fontSize: "14px", color: "#1E1E1E", fontWeight: 600 }}>
                  <span style={{ fontSize: "12px", color: "rgba(114, 114, 114, 1)", fontWeight: 600 }}>

                  </span>
                </span>
              </div>

            </div>
            <div className="header_toggle flex flex-col items-end gap-[2px]">
              <Toggle
                height={"50px"}
                icons={false}
                checked={survey_service.is_active}
                onChange={(e) => {
                  setSurveyService((prev) => ({
                    ...prev,
                    is_active: e.target.checked ? true : false,
                  }));
                }}
              />
              <p style={{ fontSize: "14px", color: "#727272", fontWeight: 500, letterSpacing: "0.2px" }}>Service is <span style={{ fontWeight: 600, color: survey_service.is_active ? "#109316" : "#727272" }}>{survey_service.is_active ? "Active" : "Inactive"}</span> </p>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", height: "70%", overflowY: "auto", gap: "10px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "15px",
                padding: "0px 60px"
              }}
            >
              <div
                style={{
                  display: "grid",
                  justifyContent: "space-between",
                  columnGap: "30px",
                  rowGap: "5px",
                  alignItems: "center",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr ",
                }}
              >
                {/* service name */}
                <div style={{ width: "100%", minHeight: "100px" }}>
                  <label
                    style={{
                      display: "block",
                      fontSize: "15px",
                      color: "#3b3b3b",
                      marginBottom: "5px"
                    }}
                  >
                    Name of the service
                  </label>
                  <Input
                    style={{ background: "rgba(243, 243, 243, 1)", height: "45px" }}
                    inputStyle={{
                      background: "rgba(243, 243, 243, 1)"
                    }}
                    maxLength={LIMITS.SERVICE_NAME}
                    type={"text"}
                    value={survey_service.name}
                    placeholder={"Service name"}
                    name={"name"}
                    onChange={(e) => {
                      if (isSpecialCharExists(e.target.value)) {
                        return;
                      }
                      setSurveyService((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }));
                    }}
                  />
                  {(survey_service.name.length === 0 || isEmptyString(survey_service.name)) && (
                    <div style={{ color: GLOBAL_STYLES.ERROR, fontSize: "12px", fontWeight: 500, marginTop: "3px" }}>
                      Please enter service name
                    </div>
                  )}
                </div>
                {/* Rate/Acre */}
                <div style={{ width: "100%", minHeight: "100px" }}>
                  <label
                    style={{
                      display: "block",
                      fontSize: "15px",
                      color: "#3b3b3b",
                      marginBottom: "5px"
                    }}
                  >
                    Rate/Km<sup>2</sup>
                  </label>
                  <Input
                    style={{ background: "rgba(243, 243, 243, 1)", height: "45px" }}
                    inputStyle={{
                      background: "rgba(243, 243, 243, 1)"
                    }}
                    maxLength={6}
                    type={"number"}
                    value={survey_service.parameters.rate_per_sq_km}
                    placeholder={"Rate per Sq km"}
                    name={"rate_per_sq_km"}
                    onChange={handleOnChangeParameters}
                  />
                  {(survey_service.parameters.rate_per_sq_km === "e" ||
                    survey_service.parameters.rate_per_sq_km <= 0 ||
                    survey_service.parameters.rate_per_sq_km.length === 0) && (
                      <div style={{ color: GLOBAL_STYLES.ERROR, fontSize: "12px", fontWeight: 500, marginTop: "3px", }}>
                        Please enter valid rate
                      </div>
                    )}
                </div>
                <div style={{ width: "100%", minHeight: "100px" }}>
                  <label
                    style={{
                      display: "block",
                      fontSize: "15px",
                      color: "#3b3b3b",
                      marginBottom: "5px"
                    }}
                  >
                    Start Date
                  </label>
                  <Input
                        style={{ background: "rgba(243, 243, 243, 1)", height: "45px" }}
                        inputStyle={{
                          background: "rgba(243, 243, 243, 1)"
                        }}
                        type={"text"}
                        value={new Date(survey_service.start_date).toLocaleDateString('en-GB', dateFormatOption)}
                        placeholder={"start_date"}
                        name={"start_date"}
                        isDisabled={true}
                      />
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <div style={{ width: "45%", minHeight: "100px" }}>
                    <label
                      style={{
                        display: "block",
                        fontSize: "15px",
                        color: "#3b3b3b",
                        marginBottom: "5px"
                      }}
                    >
                      SGST (%)
                    </label>
                    <Input
                      style={{ background: "rgba(243, 243, 243, 1)", height: "45px" }}
                      inputStyle={{
                        background: "rgba(243, 243, 243, 1)"
                      }}
                      type={"number"}
                      value={survey_service.parameters.sgst}
                      placeholder={"SGST"}
                      name={"sgst"}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        if (isValidGst(newValue) || newValue === '') {
                          setSurveyService((prev) => ({
                            ...prev,
                            parameters: { ...prev.parameters, sgst: e.target.value }
                          }))
                        }
                      }}
                    />

                  </div>
                  <div style={{ width: "45%", minHeight: "100px" }}>
                    <label
                      style={{
                        display: "block",
                        fontSize: "15px",
                        color: "#3b3b3b",
                        marginBottom: "5px"
                      }}
                    >
                      CGST (%)
                    </label>
                    <Input
                      style={{ background: "rgba(243, 243, 243, 1)", height: "45px" }}
                      inputStyle={{
                        background: "rgba(243, 243, 243, 1)"
                      }}
                      type={"number"}
                      value={survey_service.parameters.cgst}
                      placeholder={"CGST"}
                      name={"cgst"}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        if (isValidGst(newValue) || newValue === '') {
                          setSurveyService((prev) => ({
                            ...prev,
                            parameters: { ...prev.parameters, cgst: e.target.value }
                          }))
                        }
                      }}
                    />
                  </div>

                </div>
              </div>

              {/* 2nd part */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  gap: "60px",
                }}
                className="survey_locations_drones_wrapper"
              >
                {/* locations */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "180px",
                    minHeight: "180px",
                    rowGap: "0px",
                  }}
                  className="locations__wrapper"
                >
                  <div
                    style={{
                      color: GLOBAL_STYLES.FONT_COLOR_SUB,
                      fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM,
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <div style={{
                      padding: "0px",
                      fontSize: "16px",
                      marginBottom: "5px",
                      fontWeight: 500,
                      color: "rgba(59, 59, 59, 1)",

                    }}>
                      <span>Locations</span>
                      <span style={{ marginLeft: "5px" }}>{`(${locations.length})`}</span>
                    </div>
                    <Button type={"button"} text={"Add New"} icon={Plus} style={{ marginBottom: "8px", background: "#0F5EFF", padding: "8px, 16px, 8px, 16px", width: "110px", height: "35px", fontSize: "11px" }} onClick={() => setOpenAddLocationPopup(true)} />
                  </div>
                  <div
                    style={{
                      overflowY: "auto",
                      display: "grid",
                      gridTemplateColumns: "repeat(auto-fill,155px)",
                      gridTemplateRows: "repeat(auto-fill,28px)",
                      alignItems: "flex-start",
                      justifyContent: "space-around",
                      flexWrap: "wrap",
                      rowGap: "23px",
                      height: "90%",
                      minHeight: "90%",
                      border: `1px solid ${GLOBAL_STYLES.BORDER_COLOR}`,
                      background: "#FCFCFC",
                      boxShadow: "0px 0px 4px 0px rgba(114, 114, 114, 0.15)",
                      borderRadius: "5px",
                      padding: "20px 5px",
                      fontSize: "12px",
                      fontWeight: 500

                    }}
                    className="locations_container"
                  >
                    {locations.map((location, i) => (
                      <Tippy content={location.address}>

                        <div
                          key={i}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px 10px",
                            gap: "10px",
                            color: "rgba(30, 30, 30, 1)",
                            fontSize: "15px",
                            fontWeight: "500",
                            position: "relative"
                          }}
                        >
                          <input
                            disabled={!location.is_active}
                            className="input-checkbox"
                            type="checkbox"
                            checked={survey_service.locations.find(
                              (loc) => loc.id === location.id
                            )}
                            onChange={(e) => handleOnChangeLocation(e, location.id)}
                          />
                          <label
                            style={{ position: "relative", opacity: location.is_active ? 1 : 0.5 }}
                          >
                            {reduceLongName(
                              location.name,
                              10
                            )}
                          </label>
                        </div>
                      </Tippy>
                    ))}
                  </div>
                  {(locations.length === 0 && !loading) && (
                    <div style={{ color: GLOBAL_STYLES.ERROR, fontSize: "12px", fontWeight: 500, marginTop: "3px" }}>
                      Please select atleast one location
                    </div>
                  )}
                </div>

                {/* Drones types and devices */}
                <div
                  style={{
                    width: "50%",
                    height: "150px",
                    minHeight: "150px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      color: GLOBAL_STYLES.FONT_COLOR_SUB,
                      fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM,
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{
                      padding: "0px",
                      fontSize: "16px",
                      marginBottom: "5px",
                      fontWeight: 500,
                      color: "rgba(59, 59, 59, 1)",

                    }}>
                      <span>Drones and Devices</span>
                      <span style={{ marginLeft: "5px" }}>{`(${(DRONE_TYPES.length || 0) + (DEVICES.length || 0)
                        })`}</span>
                    </div>
                  </div>
                  <div
                    style={{
                      overflowY: "auto",
                      display: "grid",
                      gridTemplateColumns: "repeat(auto-fill,150px)",
                      gridTemplateRows: "repeat(auto-fill,35px)",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      rowGap: "25px",
                      height: "90%",
                      minHeight: "90%",
                      border: `1px solid ${GLOBAL_STYLES.BORDER_COLOR}`,
                      background: "rgba(252, 252, 252, 1)",
                      boxShadow: '0px 0px 4px 0px rgba(114, 114, 114, 0.15)',
                      borderRadius: "5px",
                      padding: "20px 15px",
                      fontSize: "12px",
                      fontWeight: 500
                    }}
                  >
                    {DRONE_TYPES.map((drone, i) => (
                      <Tippy content={drone}>

                        <div
                          key={i}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px 10px",
                            gap: "10px",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(59, 59, 59, 1)",
                          }}
                        >
                          <input
                            className="input-checkbox"
                            checked={survey_service.parameters.drone_types.find(
                              (d) => d === drone
                            )}
                            type="checkbox"
                            onChange={(e) => handleOnChangeDroneTypes(e, drone)}
                          />
                          <label
                          >
                            {reduceLongName(drone, 10)}
                          </label>
                        </div>
                      </Tippy>
                    ))}
                    {DEVICES.map((device, i) => (
                      <Tippy content={device}>
                        <div
                          key={i}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px 10px",
                            gap: "10px",
                            fontSize: "15px"
                          }}
                        >
                          <input
                            className="input-checkbox"
                            checked={survey_service.parameters.devices.find(
                              (dev) => dev === device
                            )}
                            type="checkbox"
                            onChange={(e) => handleOnChangeDevice(e, device)}
                          />
                          <label>{device}</label>
                        </div>
                      </Tippy>
                    ))}
                  </div>
                  {survey_service.parameters.drone_types.length === 0 &&
                    survey_service.parameters.devices.length === 0 && (
                      <div style={{ color: GLOBAL_STYLES.ERROR, fontSize: "12px", fontWeight: 500, marginTop: "3px" }}>
                        Please select atleast one device or drone
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginTop: "-25px",
              columnGap: "20px",
              padding: '5px 50px 10px 50px'
            }}
          >
            {isServiceToBeUpdated ? (
              <Button

                style={{ width: "140px", height: "32px", fontSize: "16px" }}
                text={"Save"}
                isDisabled={!isAddServiceFormFilled()}
              />
            ) : (
              <Button
                style={{ width: "140px", height: "32px", fontSize: "16px" }}
                text={"Save"}
                isDisabled={!isAddServiceFormFilled()}
              />
            )}
            <Button
              type={"button"}
              style={{ width: "140px", height: "32px", fontSize: "16px", color: "#606571", border: "1px solid #BFC8D9" }}
              text={"Cancel"}
              isBtnNotActive
              redirectTo={ROUTES.MYSERVICES}
            />
          </div>
        </form>
      </MainContentWrapper >
    </>
  );
};

export default AddSurveyService;