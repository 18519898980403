import React, { useEffect, useState } from "react";
import { getServices } from "../../apis/services";
import { Button } from "../../Components/Helper";
import SelectServices from "./SubComponents/SelectServices/SelectServices";
import ServiceCard from "./SubComponents/ServiceCard/ServiceCard";
import Plus from "../../assets/Plus.svg";
import Popup from "../../ReusableFunctions/Popup";
import Loading from "../../ReusableFunctions/Loader";
import { GLOBAL_STYLES } from "../../appConstants";
import NotFounds from "../../ReusableComponents/NotFounds";
import { login } from "../../apis/authentication";
import MainContentWrapper from "../../Components/MainContentWrapper";
const MyServices = () => {
  const [services, setServices] = useState();
  const [openSelectServicesPopup, setOpenSelectServicesPopup] = useState(false);
  const [selectedAeroMeghService, setSelectedAeroMeghService] = useState();
  const [user, setUser] = useState({});
  const [loading, setloading] = useState(true);

  const getServicesAPI = async () => {
    try {
      const user = await login()
      const services = await getServices()
      setServices(services);
      setUser(user);
      Loading.set(false);
    } catch (err) {
      Loading.set(false);
      console.log(err);
      Popup.alert("Error", err, "ERROR");
    }

  };
  useEffect(() => {
    Loading.set(true);
    document.title = "BhuMeet - My Services";
    getServicesAPI()
  }, []);
  
  return (
    <>

      {openSelectServicesPopup && (
        <SelectServices
          open={openSelectServicesPopup}
          onClose={() => setOpenSelectServicesPopup(false)}
          setSelectedAeroMeghService={(selectedAeroMeghService) => {
            setSelectedAeroMeghService(selectedAeroMeghService);
            setOpenSelectServicesPopup(false);
          }}
        />
      )}
      <MainContentWrapper header={`My Services
              ${services
          ? services.length > 9 || services.length === 0
            ? `(${services.length})`
            : `(0${services.length})`
          : 0}`}
        rightContent={user.is_admin ? ((services && services.length > 0) && <Button
          icon={Plus}
          style={{ padding: "4px, 24px, 4px, 24px", height: "35px", width: "120px", fontSize: "14px", fontWeight: 500 }}
          isDisabled={!user.is_admin}
          text={"Add New"}
          onClick={() => setOpenSelectServicesPopup(true)}
        />) : null}
      >
        <div
          style={{ display: "flex", justifyContent: "left", gap: "20px", height: "100%" }}
        >
          {services?.length > 0 ?
            services.map((service) => (
              <ServiceCard
                isEditDisabled={!user.is_admin}
                key={service.id}
                service={service}
                callback={() => getServicesAPI()}
              />
            )) : (
              services && services.length === 0 && (
                <NotFounds msg={"OOPS, No Services Found!"} onBtnClick={() => setOpenSelectServicesPopup(true)} />
              )
            )}
        </div>
      </MainContentWrapper>
    </>
  );
};

export default MyServices;
