import React from 'react'
import { ROUTES } from '../routes'
import usersIcon from "../assets/sidebaricons/customerInactive.svg";
import usersIconActive from "../assets/sidebaricons/CustomersActive.svg";

const CustomersIcon = (location) => {
    return (
        <img alt='' className={location.location === ROUTES.CUSTOMERS ? "sideBarIcon" : "sideBarIcon-not-selected"} src={location.location === ROUTES.CUSTOMERS ? usersIconActive : usersIcon} />
    )
}

export default CustomersIcon