import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import { AEROMEGH_SERVICES, DRONE_TYPES, GLOBAL_STYLES } from '../../appConstants'
import Popup from '../../ReusableFunctions/Popup'
import { getDronePilots } from '../../apis/dronePilots'
import { getAeroMeghServices } from '../../apis/services'
import { getLocations } from '../../apis/locations'
import LoaderComponent from '../../ReusableComponents/LoaderComponent'
import RevenueGraph from './SubComponents/RevenueGraph'
import { getRevenue, getRevenueForWeekOrMonth } from '../../apis/revenue'
import { addCommasToAmount, getStartAndEndDates, getWeekNumber } from '../../ReusableFunctions/reusableFunctions'
import { getAllDSPDroneUAS, getAllDronesbyUAS, } from '../../apis/drones'
import SelectComponent from '../../ReusableComponents/SelectComponent'
import Loading from '../../ReusableFunctions/Loader'
import RevenueStatisticCard from './SubComponents/RevenueStatisticCard'
import { getAllCrops} from '../../apis/crops'

const Revenue = () => {
    const [filterLoading, setfilterLoading] = useState(true)
    const [screenWidth, setScreenWidth] = useState(1366)
    const [dropDownLoading, setDropDownLoading] = useState(false)
    const [weekGraphLoading, setWeekGraphLoading] = useState(true)
    const [monthGraphLoading, setMonthGraphLoading] = useState(true)
    const [selectedFilter, setSelectedFilter] = useState("Service")
    const [dropDown, setDropDown] = useState([])
    const [droneUINs, setDroneUINs] = useState([]);
    const [selectedFilterValue, setSelectedFilterValue] = useState({ type: "service", value: "All", label: "All" })
    const [revenueInfo, setRevenueInfo] = useState({ month: 0, total: 0, week: 0, monthGraphData: [], weekGraphData: [] })
    const [graphInfo, setGraphInfo] = useState({ monthGraphData: { revenues: [], dates: [] }, weekGraphData: { revenues: [], dates: [] } })
    const [selectedWeekNo, setSelectedWeekNo] = useState(getWeekNumber(new Date())[1])
    const [selectedMonthNo, setSelectedMonthNo] = useState(new Date().getMonth())
    const [selectedmodel, setSelectedmodel] = useState({ label: "All", value: "All" });
    const [selectedUIN, setSelectedUIN] = useState({ label: "", value: "" });

    const getDronesFunc = async () => {
        try {
            const droneUAS = await getAllDSPDroneUAS();
            setDropDown([{ label: "All", value: "All" }, ...droneUAS.map(d => ({ label: d.model_name, value: d.id }))])
            setfilterLoading(false)
        } catch (e) {
            setfilterLoading(false)
            Popup.alert("Error", e, "ERROR")
        }
    }
    const getCropsFunc = async () => {
        try {
            const { crops } = await getAllCrops();
            setDropDown(crops.map(s => ({ label: s.name, value: s.name })))
            setfilterLoading(false)
        } catch (e) {
            setfilterLoading(false)
            Popup.alert("Error", e, "ERROR")
        }
    }
    const getLocationsFunc = async () => {
        try {
            const locations = await getLocations();
            setDropDown(locations.map(s => ({ label: s.address, value: s.id })))
            setfilterLoading(false)
        } catch (e) {
            setfilterLoading(false)
            Popup.alert("Error", e, "ERROR")
        }
    }
    const getAeroMeghServicesListFunc = async () => {
        try {
            const res = await getAeroMeghServices();
            setDropDown([{ label: "All", value: "All" }, ...res.aeroMeghServices.filter(ser => ser.name !== AEROMEGH_SERVICES.INSURANCE && ser.name !== AEROMEGH_SERVICES.TRAINING).map(s => ({ label: s.name, value: s.name }))])
            setfilterLoading(false)
            Loading.set(false)
        } catch (e) {
            setfilterLoading(false)
            Loading.set(false)
            Popup.alert("Error", e, "ERROR")
        }
    }
    const getPilotsListFunc = async () => {
        try {
            const res = await getDronePilots();
            setDropDown(() => res.dronePilots.map(p => ({ label: p.first_name, value: p.id })))
            setfilterLoading(false)
        } catch (e) {
            setfilterLoading(false)
            Popup.alert("Error", e, "ERROR")
        }
    }
    const getRevenueWeekFunc = async (weekNumber, filter, type) => {
        setWeekGraphLoading(true)
        const { startDate, endDate } = await getStartAndEndDates("week", parseInt(weekNumber))
        try {
            const revenue = await getRevenueForWeekOrMonth('Week', startDate, endDate, filter, type)
            setGraphInfo(prev => ({ ...prev, weekGraphData: { revenues: revenue.graphData, dates: revenue.graphDates } }))
            setWeekGraphLoading(false)
        } catch (e) {
            Popup.alert("Error", e, "ERROR")
        }
    }
    const getRevenueMonthFunc = async (monthNumber, filter, type) => {
        setMonthGraphLoading(true)
        const { startDate, endDate } = await getStartAndEndDates("month", parseInt(monthNumber))
        try {
            const revenue = await getRevenueForWeekOrMonth('Month', startDate, endDate, filter, type)
            setGraphInfo(prev => ({ ...prev, monthGraphData: { revenues: revenue.graphData, dates: revenue.graphDates } }))
            setMonthGraphLoading(false)
        } catch (e) {
            Popup.alert("Error", e, "ERROR")
        }
    }
    const getRevenueFunc = async (filterValue, type) => {
        setDropDownLoading(true);
        try {
            const revenue = await getRevenue(filterValue, type)
            setRevenueInfo(prev => ({ ...prev, ...revenue }))
            setDropDownLoading(false)
            setfilterLoading(false)
        } catch (e) {
            setDropDownLoading(false);
            console.log(e);
        }
    }
    const getdroneUINs = async (uasId) => {
        try {
            const alldrones = await getAllDronesbyUAS(uasId);
            setDroneUINs([{ label: "All", value: "All" }, ...alldrones.map((d) => ({
                label: d.drone_uin,
                value: d.id
            }))])
        } catch (err) {
            console.log("error", err);
        }
    }
    const resetWeekAndMonth = () => {
        setSelectedMonthNo(() => new Date().getMonth());
        setSelectedWeekNo(() => getWeekNumber(new Date())[1])
    }

    useEffect(() => {
        Loading.set(true);
        document.title = "BhuMeet - Revenue";
        try {
            const screenWidth = window.innerWidth;
            setScreenWidth(screenWidth)
            getAeroMeghServicesListFunc();
        } catch (err) {
            console.log("error", err);
            Loading.set(false);
        }
    }, [])

    useEffect(() => {
        // if (selectedFilterValue.type === "service" && selectedFilterValue.value === "All") return;
        setRevenueInfo(prev => ({ ...prev, month: 0, total: 0, week: 0, }))
        setGraphInfo({ monthGraphData: { revenues: [], dates: [] }, weekGraphData: { revenues: [], dates: [] } })
        if (selectedFilter) {
            setfilterLoading(true)
            switch (selectedFilter) {
                case "Pilot": getPilotsListFunc()
                    let updatedPilot = { ...selectedFilterValue, type: "pilot", value: "", label: "" }
                    getRevenueFunc(updatedPilot, selectedFilter)
                    break;
                case "Service": getAeroMeghServicesListFunc()
                    if (selectedFilterValue.value === "All" && selectedFilterValue.type == "service") {

                    } else {
                        let updatedService = { ...selectedFilterValue, type: "service", value: "", label: "" }
                        getRevenueFunc(updatedService, selectedFilter)
                    }
                    break;
                case "Location": getLocationsFunc()
                    let updatedLocation = { ...selectedFilterValue, type: "location", value: "", label: "" }
                    getRevenueFunc(updatedLocation, selectedFilter)
                    break;
                case "Crop": getCropsFunc();
                    let updatedCrop = { ...selectedFilterValue, type: "crop", value: "", label: "" }
                    getRevenueFunc(updatedCrop, selectedFilter)
                    break;
                case "Drone": getDronesFunc();
                    break;
            }
        }
        setSelectedmodel(() => ({
            label: "All",
            value: "All",
        }))
    }, [selectedFilter])

    useEffect(() => {
        setDropDownLoading(true)
        if (selectedmodel && selectedmodel.value !== "All") {
            setSelectedUIN(() => ({ label: "All", value: "All" }))
            getdroneUINs(selectedmodel.value)
        }
        if (selectedFilter == "Drone" && selectedmodel?.value && selectedmodel.value === "All") {
            getRevenueFunc({ drone: selectedmodel?.value, droneuin: selectedUIN?.value }, selectedFilter)
        }
    }, [selectedmodel])

    useEffect(() => {
        if (selectedUIN.value) {
            if (selectedFilter == "Drone" && selectedmodel?.value) {
                getRevenueFunc({ drone: selectedmodel?.value, droneuin: selectedUIN?.value }, selectedFilter)
            }
        }
    }, [selectedUIN])

    useEffect(() => {
        if (selectedFilterValue.type && selectedFilterValue.value) {
            getRevenueFunc(selectedFilterValue, selectedFilter)
        }
    }, [selectedFilterValue.type, selectedFilterValue.value])

    useEffect(() => {
        if (selectedWeekNo !== undefined && selectedFilter !== "Drone" && selectedFilterValue.type !== "drone") {
            getRevenueWeekFunc(selectedWeekNo, selectedFilterValue, selectedFilter)
        }
    }, [selectedFilter, selectedFilterValue, selectedWeekNo])

    useEffect(() => {
        if (selectedMonthNo !== undefined && selectedFilter !== "Drone" && selectedFilterValue.type !== "drone") {
            getRevenueMonthFunc(selectedMonthNo, selectedFilterValue, selectedFilter)
        }
    }, [selectedFilter, selectedFilterValue, selectedMonthNo])

    useEffect(() => {
        if (selectedMonthNo !== undefined && selectedFilter === "Drone" && selectedFilterValue.type === "drone") {
            getRevenueMonthFunc(selectedMonthNo, { drone: selectedmodel?.value, droneuin: selectedUIN?.value }, selectedFilter)
        }
    }, [selectedMonthNo, selectedmodel.value, selectedUIN.value, selectedFilter])

    useEffect(() => {
        if (selectedWeekNo !== undefined && selectedFilter === "Drone" && selectedFilterValue.type === "drone") {
            getRevenueWeekFunc(selectedWeekNo, { drone: selectedmodel?.value, droneuin: selectedUIN?.value }, selectedFilter)
        }
    }, [selectedWeekNo, selectedmodel.value, selectedUIN.value, selectedFilter])

    return (<MainContentWrapper padding="" header={'Revenue'}>

        {/* ---- 1st Section ----*/}
        <div className='revenue-first-section'>
            <div className='revenue-first-section-label'> {selectedFilter ? `Revenue by ${selectedFilter}` : "Total Revenue"}</div>
            <div className='revenue-first-section-toggle-bar' >
                <div
                    className='revenue-btn'
                    style={{
                        color: !(selectedFilter === "Service") ? '#0F5EFF' : 'white',
                        background: (selectedFilter === "Service") ? '#0F5EFF' : 'white',
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px"
                    }}
                    onClick={() => {
                        setSelectedFilterValue(prev => ({ ...prev, type: "service", value: "", label: "" }))
                        setSelectedFilter("Service")
                        resetWeekAndMonth();
                    }} isBtnNotActive={!(selectedFilter === "Service")} text={"Services"} >
                    Services
                </div>
                <div
                    className='revenue-btn'
                    style={{
                        color: !(selectedFilter === "Pilot") ? '#0F5EFF' : 'white',
                        background: (selectedFilter === "Pilot") ? '#0F5EFF' : 'white',
                        borderLeft: '1px solid #D9D9D9'
                    }}
                    onClick={() => {
                        setSelectedFilterValue(prev => ({ ...prev, type: "pilot", value: "", label: "" }))
                        setSelectedFilter("Pilot")
                        resetWeekAndMonth();
                    }} isBtnNotActive={!(selectedFilter === "Pilot")} text={"Pilot"} >
                    Pilot
                </div>
                <div
                    className='revenue-btn'
                    style={{
                        color: !(selectedFilter === "Location") ? '#0F5EFF' : 'white',
                        background: (selectedFilter === "Location") ? '#0F5EFF' : 'white',
                        borderLeft: '1px solid #D9D9D9'
                    }}
                    onClick={() => {
                        setSelectedFilterValue(prev => ({ ...prev, type: "location", value: "", label: "" }))
                        setSelectedFilter("Location")
                        resetWeekAndMonth();
                    }} isBtnNotActive={!(selectedFilter === "Location")} text={"Location"} >
                    Location
                </div>
                <div
                    className='revenue-btn'
                    style={{
                        color: !(selectedFilter === "Crop") ? '#0F5EFF' : 'white',
                        background: (selectedFilter === "Crop") ? '#0F5EFF' : 'white',
                        borderLeft: '1px solid #D9D9D9'
                    }}
                    onClick={() => {
                        setSelectedFilterValue(prev => ({ ...prev, type: "crop", value: "", label: "" }))
                        setSelectedFilter("Crop")
                        resetWeekAndMonth();
                    }} isBtnNotActive={!(selectedFilter === "Crop")} text={"Crop"} >
                    Crops
                </div>
                <div
                    className='revenue-btn'
                    style={{
                        color: !(selectedFilter === "Drone") ? '#0F5EFF' : 'white',
                        background: (selectedFilter === "Drone") ? '#0F5EFF' : 'white',
                        borderLeft: '1px solid #D9D9D9',
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px"
                    }}
                    onClick={() => {
                        setSelectedFilterValue(prev => ({ ...prev, type: "drone", value: "", label: "" }))
                        setSelectedFilter("Drone")
                        resetWeekAndMonth();
                    }} isBtnNotActive={!(selectedFilter === "Drone")} text={"Drone"} >
                    Drone
                </div>
            </div>
        </div>
        {/* ---- 1st Section End ---- */}

        {/* ---- 2nd Section ---- */}
        <div className='revenue-second-section' >
            {selectedFilter !== "Drone" ?
                <div style={{ display: "flex", alignItems: "center", }}>
                    <>
                        {filterLoading ?
                            <div style={{ width: "150px", position: "relative", height: "", background: "red", display: "flex", alignItems: "center" }}>
                                <LoaderComponent height={"15%"} width={"15%"} top={'-10px'} left={0} />
                            </div>
                            : <div className='revenue-second-section-dropdown-label' >{`Select ${selectedFilter}:`}</div>}
                        <SelectComponent
                            controlStyle={{ width: screenWidth == 1920 ? "475px" : "339px", background: "#F3F3F3", height: screenWidth == 1920 ? "40px" : "30px", minHeight: "30px", border: "1px solid #D9D9D9", color: "black", fontSize: "13px" }}
                            valueControlStyle={{ padding: "2px 8px 4px 8px", margin: "0" }}
                            dropdownIndicatorStyle={{ padding: "0", margin: "0" }}
                            indicatorContainerStyle={{ width: "16px" }}
                            optionStyle={{ fontSize: '13px', display: "flex", alignItems: "center", padding: "0px 10px", height: "max-content", minHeight: "max-content" }}
                            singleValueStyle={{ color: "#1e1e1e" }}
                            disabled={filterLoading}
                            value={selectedFilterValue.label ? { value: selectedFilterValue.value, label: selectedFilterValue.label } : ""}
                            list={dropDown}
                            onChange={e => {
                                setSelectedFilterValue(prev => ({ ...prev, value: e.value, label: e.label }))
                            }}
                            isDisabled={filterLoading}
                        />
                    </>
                </div> :
                <div className='revenue-second-section-drone' >
                    <div style={{ display: "flex", alignItems: "center", }}>
                        {filterLoading ? <div style={{ width: "150px", position: "relative", height: "", background: "red", display: "flex", alignItems: "center" }}>
                            <LoaderComponent height={"15%"} width={"15%"} top={'-10px'} left={0} />
                        </div>
                            : <div className='revenue-second-section-dropdown-label'>{`Select Model:`}</div>}
                        <SelectComponent

                            controlStyle={{ width: screenWidth == 1920 ? "475px" : "339px", background: "#F3F3F3", height: screenWidth == 1920 ? "40px" : "30px", minHeight: "30px", border: "1px solid #D9D9D9", color: "black", fontSize: "13px" }}
                            valueControlStyle={{ padding: "2px 8px 4px 8px", margin: "0" }}
                            dropdownIndicatorStyle={{ padding: "0", margin: "0" }}
                            indicatorContainerStyle={{ width: "16px" }}
                            optionStyle={{ fontSize: '13px', display: "flex", alignItems: "center", padding: "0px 10px", height: "max-content", minHeight: "max-content" }}
                            singleValueStyle={{ color: "#1e1e1e" }}
                            disabled={filterLoading}
                            value={selectedmodel?.value ? { value: selectedmodel?.value, label: selectedmodel?.label } : ""}
                            list={dropDown}
                            onChange={e => {
                                setDropDownLoading(true)
                                setSelectedmodel(() => ({
                                    label: e.label,
                                    value: e.value,
                                }))
                            }}
                            isDisabled={filterLoading}
                        />
                    </div>
                    {selectedmodel.value !== "All" && (
                        <div style={{ display: "flex", alignItems: "center", }}>

                            {filterLoading ? <div style={{ width: "150px", position: "relative", height: "", background: "red", display: "flex", alignItems: "center" }}>
                                <LoaderComponent height={"15%"} width={"15%"} top={'-10px'} left={0} />
                            </div>
                                : <div style={{ width: "104px", marginRight: "10px", color: '#3B3B3B', fontSize: '12px', fontWeight: 500 }}>{`Select DroneUIN:`}</div>}
                            <SelectComponent

                                controlStyle={{ width: screenWidth == 1920 ? "475px" : "339px", background: "#F3F3F3", height: screenWidth == 1920 ? "40px" : "30px", minHeight: "30px", border: "1px solid #D9D9D9", color: "black", fontSize: "13px" }}
                                valueControlStyle={{ padding: "2px 8px 4px 8px", margin: "0" }}
                                dropdownIndicatorStyle={{ padding: "0", margin: "0" }}
                                indicatorContainerStyle={{ width: "16px" }}
                                optionStyle={{ fontSize: '13px', display: "flex", alignItems: "center", padding: "0px 10px", height: "max-content", minHeight: "max-content" }}
                                singleValueStyle={{ color: "#1e1e1e" }}
                                disabled={filterLoading}
                                value={selectedUIN?.label ? { value: selectedUIN?.value, label: selectedUIN?.label } : ""}
                                list={droneUINs}
                                onChange={e => {
                                    setSelectedUIN(() => ({
                                        label: e.label,
                                        value: e.value,
                                    }))
                                }}
                                isDisabled={filterLoading}
                            />
                        </div>)}
                </div>}
        </div>
        {/* ---- 2nd Section ---- */}

        {/* ---- 3rd Section ---- */}
        <div className='revenue-third-section' >
            <div className='revenue-third-section-wraper'>
                <div className='revenue-third-section-heading' >Revenue Statistics</div>
                <div className='revenue-third-section-card-holder'>
                    <RevenueStatisticCard title="Total Revenue" rupee={addCommasToAmount(revenueInfo.total)} />
                    <RevenueStatisticCard title="This Month" rupee={addCommasToAmount(revenueInfo.month)} />
                    <RevenueStatisticCard title="This Week" rupee={addCommasToAmount(revenueInfo.week)} />
                </div>
            </div>
        </div>
        {/* ---- 3rd Section End ---- */}

        {/* ---- 4th Section ------ */}
        <div >
            <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", gap: "10px" }}>
                <div className='revenue-third-section-heading'>Trend</div>
                <div style={{ display: "flex", height: "90%", width: "100%", gap: screenWidth == 1920 ? "16px" : "12px", height: "45.9vh" }}>
                    <RevenueGraph
                        load={weekGraphLoading}
                        inter={"week"}
                        dropdownValue={selectedWeekNo}
                        onChange={(selected) => setSelectedWeekNo(parseInt(selected.value))}
                        rev={graphInfo.weekGraphData} />
                    <RevenueGraph
                        load={monthGraphLoading}
                        inter={"month"}
                        dropdownValue={selectedMonthNo}
                        onChange={(selected) => setSelectedMonthNo(new Date(selected.value).getMonth())
                        }
                        rev={graphInfo.monthGraphData} />
                </div>
            </div>
        </div>
        {/* ---- 4th Section End------ */}

    </MainContentWrapper>)
}

export default Revenue