import React from 'react'
import Toggle from 'react-toggle'
import { Controller, useFormContext } from "react-hook-form"
import { findInputError } from '../utils/findInputError'
import { isFormInvalid } from '../utils/isFormValid'
import InputError from './InputError'
import { capitalizeFirstLetter } from '../ReusableFunctions/reusableFunctions'

const ToggleField = ({ label, onText, offText, name, validation, defaultValue }) => {
    const {
        control,
        formState: { errors },
        clearErrors
    } = useFormContext()

    const inputError = findInputError(errors, name)
    const isInvalid = isFormInvalid(inputError)

    return (
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", rowGap: "5px" }}>
            <div style={{ fontSize: "13px", color: "#3B3B3B" }}>{capitalizeFirstLetter(label)}</div>
            <div
                style={{
                    display: "flex",
                    columnGap: "15px",
                    fontSize: "13px",
                    padding: "0 0 0 3px"
                }}
            >
                <label style={{ fontSize: "14px", fontWeight: 400 }}>{offText || 'No'}</label>
                <label style={{ display: "flex", alignItems: "center" }}>
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={defaultValue}
                        rules={validation}
                        render={({ field }) => (
                            <Toggle
                                {...field}
                                checked={field.value}
                                name={name}
                                icons={false}
                                onChange={(e) => {
                                    clearErrors(name)
                                    field.onChange(e.target.checked)
                                }}
                            />
                        )}
                    />
                </label>
                <label style={{ fontSize: "14px", fontWeight: 400 }}>{onText || 'Yes'}</label>
            </div>
            <div style={{ height: "15px" }}>
                {isInvalid && (
                    <InputError
                        inputError={inputError}
                    />
                )}
            </div>
        </div>
    )
}

export default ToggleField
