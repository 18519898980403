import React, { useState, useEffect } from 'react';
import { getRequests } from '../apis/requests';
import { ROUTES } from '../routes';
import { Link } from "react-router-dom";
import bell from "../assets/bell.svg"
import Popup from '../ReusableFunctions/Popup';
import dateFormat from 'dateformat';
import LoaderComponent from '../ReusableComponents/LoaderComponent';
import { CrossCloseButton } from './Helper';
import noRequest from '../assets/no-request.svg'
import NotFounds from '../ReusableComponents/NotFounds';
let interval = null

const Notifications = () => {
  const [loading, setLoading] = useState(true)
  const [notifications, setNotifications] = useState(null);
  const [isOpened, setIsOpened] = useState(false)
  const [newNotificationsCount, setNewNotificationsCount] = useState(0)

  const fetchNotifications = async () => {
    try {
      const fromDate = localStorage.getItem("last_notification_fetched");
      const toDate = new Date().toISOString()
      if (fromDate) {
        const requests = await getRequests(null, null, null, null, null, fromDate, toDate)
        const filterRequestsWithUser = requests?.requests.filter((r) => r?.user_id)
        if (filterRequestsWithUser?.length > 0) {
          setNotifications(filterRequestsWithUser?.map(request => {
            return ({
              id: request.id,
              title: "New request received !",
              message: `Hey , you just received new ${(String(request.type)).toLowerCase()} from ${request.user_name}. Check 
              request and take necessary action`,
              date: request.date_created,
              redirectData: {
                route: ROUTES.VIEWREQUEST,
                state: {
                  requestId: request.id, requestType: request.type
                }
              }
            })
          }));

        } else {
          setNotifications([]);
        }
      } else {
        localStorage.setItem("last_notification_fetched", toDate);
        localStorage.setItem("last_notification_seen_count", 0);
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Popup.alert("Error", error, 'ERROR')
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {
    const lastNotificationsSeenCount = Number(localStorage.getItem("last_notification_seen_count"))
    if (notifications) {
      if (notifications.length > 0 && notifications.length !== (lastNotificationsSeenCount)) {
        setNewNotificationsCount(notifications.length - lastNotificationsSeenCount)
      } else if (notifications.length === 0) {
        localStorage.setItem("last_notification_seen_count", 0);
        setNewNotificationsCount(0)
      }
    }
  }, [notifications])

  const clearNotifications = () => {
    localStorage.setItem("last_notification_fetched", new Date().toISOString())
    localStorage.setItem("last_notification_seen_count", 0);
    setNewNotificationsCount(0)
    setNotifications([])
  }

  useEffect(() => {
    if (isOpened) fetchNotifications()
  }, [isOpened])

  useEffect(() => {
    const unlisten = () => {
      window.removeEventListener('popstate', fetchNotifications);
    };

    window.addEventListener('popstate', fetchNotifications);

    return () => {
      unlisten();
    };
  }, []);

  useEffect(() => {
    fetchNotifications()
    if (!interval) {
      interval = setInterval(fetchNotifications, 300000)
      return () => {
        if (interval) clearInterval(interval)
      }
    }
  }, []);

  const handleOutsideClick = (e) => {
    if (e.target.id === "modal-overlay") {
      setIsOpened(false);
    }
  };

  const toggleNotification = () => {
    if (isOpened) {
      localStorage.setItem("last_notification_seen_count", notifications?.length || 0);
      setNewNotificationsCount(0)
    }
    setIsOpened(!isOpened)
  }

  return (
    <>
      <div onClick={toggleNotification} style={{ padding: "7px", backgroundColor: "#84848410", borderRadius: "50%", cursor: "pointer", position: "relative", right: "20px" }}>
        <img src={bell} alt='bell' style={{ width: "16px", objectFit: "cover" }} />
        {newNotificationsCount > 0 ?
          <div style={{ position: 'absolute', top: "5px", left: "15px", width: "10px", height: '10px', background: 'red', border: '2px solid white', borderRadius: "50%" }}></div>
          : ""
        }
      </div>
      {isOpened ?
        <div id="modal-overlay" onClick={handleOutsideClick} style={{ position: "absolute", width: "100vw", height: "100vh", top: 0, left: 0, backgroundColor: "#00000014" }}>
          <div className='notifications_wrapper'>
            <div className='notifications_header'>
              <h2>Notifications</h2>
              <div className='notifications_header_buttons'>
                <div onClick={() => clearNotifications()} className='notification_clear_btn'>
                  Clear All
                </div>
                <CrossCloseButton onClick={() => {
                  setIsOpened(false);
                }} width={"16px"} />
              </div>
            </div>
            {loading ?
              <div style={{ padding: "15px", height: "330px", overflow: "auto", position: 'relative' }}>
                <LoaderComponent height={'5%'} width={'5%'} />
              </div> :
              notifications?.length > 0 ?
                <ul className='notifications_list_wrapper' >
                  {notifications?.map((notification, key) => (
                    <div className='single_notification_wrapper'>
                      <div className='single_notification_header'>
                        <div className='single_notification_title'>{notification.title} </div>
                        {key < newNotificationsCount ?
                          <div className='unseen_notification '>•</div>
                          : ""
                        }
                      </div>
                      <li className='single_notification_message' key={notification.id}>
                        {notification.message}
                      </li>
                      <div className='single_notification_footer'>
                        <div className='notification_date_time_wrapper'>
                          <div>{dateFormat(notification.date, "dd mmmm yyyy")}</div>
                          <div>{dateFormat(notification.date, "hh:MM TT")}</div>
                        </div>
                        {
                          (notification?.id === null || notification?.id === '') ? (
                            <div className='view_request_button view_request_inactive' onClick={() => {
                              const lastSeenCount = localStorage.getItem("last_notification_seen_count")
                              localStorage.setItem("last_notification_seen_count", lastSeenCount > 0 ? Number(lastSeenCount) - 1 : lastSeenCount);
                              setNewNotificationsCount((prev) => prev > 0 ? prev - 1 : prev)
                              setIsOpened(false)
                            }}>
                              View request
                            </div>
                          ) :
                            <Link to={notification.redirectData.route} state={notification.redirectData.state} onClick={() => {
                              const lastSeenCount = localStorage.getItem("last_notification_seen_count")
                              localStorage.setItem("last_notification_seen_count", lastSeenCount < notifications?.length ? Number(lastSeenCount) + 1 : lastSeenCount);
                              setNewNotificationsCount((prev) => prev > 0 ? prev - 1 : prev)
                              setIsOpened(false)
                            }}>
                              <div className='view_request_button'>
                                View request
                              </div>
                            </Link>
                        }
                      </div>
                    </div>
                  ))}
                </ul> :
                <div className='notifications_empty'>
                  <NotFounds imgUrl={noRequest} msg={"No new notification!"} hideBtn imgStyle={{ width: "100px" }} />
                </div>
            }
          </div>
        </div >
        : <></>
      }
    </>
  );
};

export default Notifications;
