import React, { useEffect, useState } from "react";
import { getLocations } from "../../apis/locations";
import Loader from "../../ReusableComponents/Loader";
import LocationCard from "./SubComponents/LocationCard";
import { Button } from "../../Components/Helper";
import AddLocation from "./SubComponents/AddLocation";
import Popup from "../../ReusableFunctions/Popup";
import Loading from "../../ReusableFunctions/Loader";
import { GLOBAL_STYLES } from "../../appConstants";
import Plus from "../../assets/Plus.svg";
import NotFounds from "../../ReusableComponents/NotFounds";
import { login } from "../../apis/authentication";
import MainContentWrapper from "../../Components/MainContentWrapper";

const MyLocations = () => {
  const [locations, setLocations] = useState([]);
  const [filterLocations, setFilterLocations] = useState();
  const [user, setUser] = useState({})
  const [openAddLocationPopup, setOpenAddLocationPopup] = useState(false);
  const [searchString, setSearchString] = useState('')

  const getLocationsAPI = async () => {
    try {
      Loading.set(true);
      const user = await login()
      const locations = await getLocations()
      setUser(user)
      setLocations(locations);
      setFilterLocations(locations)
      Loading.set(false);
    } catch (err) {
      Loading.set(false);
      console.log(err);
      Popup.alert("Error", err, "ERROR");
    }
  }

  const onUpdateLocation = (location) => {
    setLocations(() => locations.map((l) => (l.id === location.id ? location : l)));
    if (searchString === '') {
      setFilterLocations(() => locations.map((l) => (l.id === location.id ? location : l)));
    } else {
      const filtered = locations.filter(loc =>
      ((loc.name.toLowerCase().includes(searchString.toLowerCase()) ||
        loc.address.toLowerCase().includes(searchString.toLowerCase())) && loc.id !== location.id))

      setFilterLocations(() => [location, ...filtered]);
    }
  };

  const onAddLocation = (location) => {
    setSearchString("");
    let newLocation = { ...location, pilots_count: "0", total_requests: "0", drones_count: "0" }
    setLocations([newLocation, ...locations]);
    setFilterLocations([newLocation, ...locations]);
  };

  useEffect(() => {
    let filterLoc = locations.filter(loc => loc.name.toLowerCase().includes(searchString.toLocaleLowerCase()) || loc.address.toLowerCase().includes(searchString.toLocaleLowerCase()))
    if (searchString == '') setFilterLocations(locations);
    else setFilterLocations(filterLoc)
  }, [searchString])

  useEffect(() => {
    document.title = "BhuMeet - My Locations";
    getLocationsAPI();
  }, []);

  return (
    <>
      {openAddLocationPopup && (
        <AddLocation
          open={openAddLocationPopup}
          onClose={() => setOpenAddLocationPopup(false)}
          onAddLocation={onAddLocation}
        />
      )}
      <MainContentWrapper header={`My Locations ${locations ?
        filterLocations?.length > 9 || filterLocations?.length === 0
          ? `(${filterLocations?.length})`
          : `(0${filterLocations?.length})`
        : 0}`}

        rightContent={user.is_admin ?
          ((locations && locations.length > 0) &&
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
              <input
                type="text"
                placeholder="Search Location"
                value={searchString || ''}
                onChange={(e) => {
                  setSearchString(e.target.value)
                  console.log(searchString);
                }}
                style={{ height: '100%', borderRadius: "4px", border: "1px solid #BFC8D9", padding: "7px", fontSize: "12px" }}
              />
              <Button
                icon={Plus}
                isDisabled={!user.is_admin}
                style={{ padding: "4px, 24px, 4px, 24px", height: "35px", width: "120px", fontSize: "14px", fontWeight: 500 }}
                text={"Add New"}
                onClick={() => setOpenAddLocationPopup(true)}
              />
            </div>) :
          <div>
            <input type="text" placeholder="Search Location"
              value={searchString || ''}
              style={{
                padding: '0 5px',
                outline: 'none',
                border: '1px solid #d9d9d9',
                borderRadius: '5px',
                fontWeight: '500',
                fontSize: '13px',
                height: '100%',
              }}
              onChange={(e) => {

                setSearchString(e.target.value)
                console.log(searchString);
              }}
            />
          </div>
        }
      >
        {filterLocations && filterLocations.length > 0 ?
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill,325px)",
              gridTemplateRows: "repeat(auto-fill,335px)",
              gap: "13px 16px",
              overflowY: "auto",
              height: "100%",
            }}
          >
            {filterLocations.map((location) => (
              <LocationCard
                isEditDisabled={!user.is_admin}
                key={location.id}
                location={location}
                onUpdateLocation={onUpdateLocation}
              />
            ))}
          </div>
          : filterLocations && filterLocations.length === 0 && <NotFounds msg={"OOPS,No Locations Found!"} hideBtn={!user.is_admin} onBtnClick={() => setOpenAddLocationPopup(true)} />}
      </MainContentWrapper >
    </>
  );
};

export default MyLocations;
