import React from 'react'
import { Button, CrossCloseButton } from '../../../../../Components/Helper'
import { agroChemicleNameValidation, } from '../../../../../utils/inputValidations'
import Form from '../../../../../ReusableComponents/Form'
import Modal from '../../../../../ReusableComponents/Modal'
import InputField from '../../../../../ReusableComponents/InputField'

const AddPesticides = ({ open, onClose, onPesticidesAdd }) => {
    return (
        <Modal divider contentStyle={{ width: "500px", height: 'fit-content' }} open={open} onClose={onClose}>
            <Form
                onSubmitForm={(data) => {
                    if (data?.subsidy) {
                        data.subsidy = Number(data.subsidy)
                    } else data.subsidy = null
                    onPesticidesAdd(data)
                }}
            >
                {(onSubmit) =>
                    <>
                        <div style={{ padding: "22px 30px" }}>
                            <CrossCloseButton
                                width={"15px"}
                                style={{ right: "25px", top: "18px", position: "absolute" }}
                                onClick={() => onClose()}
                            />
                            <div style={{
                                fontSize: "18px",
                                fontWeight: "600",
                                color: "#252525",
                            }}>
                                Add Agro Chemical
                            </div>
                        </div>
                        <hr style={{ width: "100%" }} />
                        <div style={{
                            padding: "10px 30px",
                            display: "flex",
                            flexDirection: 'column',
                            gap: "0.6rem",
                            marginTop: "5px"
                        }}>
                            <p style={{ color: "#1E1E1E", fontSize: "15px", fontWeight: 500 }}>
                                Enter agro chemical which you want to add in your system
                            </p>
                            <div style={{ minHeight: "80px", width: "98%" }}>
                                <InputField
                                    name={'chemical_name'}
                                    placeholder={"Agro chemical name"}
                                    label={"Agro chemical"}
                                    validation={agroChemicleNameValidation}
                                />
                            </div>
                            <div style={{ minHeight: "80px", width: "98%" }}>
                                <InputField
                                    validation={{
                                        min: {
                                            value: 0,
                                            message: "Number must be at least 0",
                                        },
                                        max: {
                                            value: 99999,
                                            message: "Number must be less than or equal to 99999",
                                        },
                                        validate: {
                                            isWholeNumber: (value) =>
                                                Number.isInteger(Number(value)) || "Number must be a whole number",
                                        },
                                    }}
                                    type={'number'}
                                    name={'subsidy'}
                                    placeholder={"Agro chemical subsidy"}
                                    label={"Subsidy (optional)"}
                                />
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    padding: "5px 8px 5px 0px",
                                    gap: "0.8rem",
                                }}
                            >
                                <Button
                                    className={"btn-large lg-cancel-btn "}
                                    text={"Cancel"}
                                    type={"button"}
                                    onClick={() => onClose()}
                                    isBtnNotActive={true}
                                    style={{ width: "110px" }}
                                />
                                <Button
                                    className={"btn-large"}
                                    text={"Add"}
                                    type="submit"
                                    onClick={() => onSubmit()}
                                    style={{ width: "110px" }}
                                />
                            </div>
                        </div>
                    </>
                }
            </Form>
        </Modal>
    )
}

export default AddPesticides