import React, { useEffect, useRef, useState } from "react";
import ProfileSetup from "./SubComponents/ProfileSetup/ProfileSetup";
import { login } from "../../apis/authentication";
import { GLOBAL_STYLES } from "../../appConstants";
import Loading from "../../ReusableFunctions/Loader";
import Popup from "../../ReusableFunctions/Popup";
import profileBG from "../../assets/profileBG.png"
import phone from "../../assets/phone.svg"
import envelope from "../../assets/message.svg"
import lockSimple from "../../assets/lock.svg"
import mapPin from "../../assets/locationPin.svg"
import verify from "../../assets/CheckCircle.svg"
import ChangePassword from "./SubComponents/ProfileSetup/SubComponents/ChangePassword";
import EditName from "./SubComponents/ProfileSetup/SubComponents/EditName";
import EditPhone from "./SubComponents/ProfileSetup/SubComponents/EditPhone";
import EditEmail from "./SubComponents/ProfileSetup/SubComponents/EditEmail";
import EditCompanyName from "./SubComponents/ProfileSetup/SubComponents/EditCompanyName";
import EditCompanyAdd from "./SubComponents/ProfileSetup/SubComponents/EditCompanyAdd";
import Tippy from "@tippyjs/react";
import { reduceLongName } from "../../ReusableFunctions/reusableFunctions";
import MainContentWrapper from "../../Components/MainContentWrapper";
import LoaderComponent from "../../ReusableComponents/LoaderComponent";
import { QRCodeSVG } from 'qrcode.react';
import ShareAppLinkPopup from "./SubComponents/ProfileSetup/SubComponents/ShareAppLinkPopup";
import DetailsWrapper from "./SubComponents/ProfileSetup/SubComponents/DetailsWrapper";
import EditButton from "./SubComponents/ProfileSetup/SubComponents/EditButton";
import SideIcon from "./SubComponents/ProfileSetup/SubComponents/SideIcon";
import Toggle from "react-toggle";
import { updateDSPSettingsFun, updateUserData, uploadCompanyLogo, uploadProfilePic } from "../../apis/users";
import { useDispatch } from "react-redux";
import { setUser as setUserReducer } from "../../reducers/userReducer";
import copybtn from '../../assets/copy.png';
import copyDone from '../../assets/checkmark.png';
import cameraIcon from '../../assets/camera.svg';
import FileUploader from "../../ReusableComponents/FileUploader";

const Profile = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [isNameEdit, setIsNameEdit] = useState(false);
  const [isPhoneEdit, setIsPhoneEdit] = useState(false);
  const [isEmailEdit, setIsEmailEdit] = useState(false);
  const [isCompanyNameEdit, setCompanyNameEdit] = useState(false);
  const [isCompanyAddEdit, setCompanyAddEdit] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false)
  const [isShareAppLinkPopup, setIsdShareAppLinkPopup] = useState(false)
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const ProfilePicRef = useRef(null);
  const companyLogoRef = useRef(null);

  const copyDspId = () => {
    if (user?.dsp?.dsp_id) {
      const tempTextArea = document.createElement('textarea');
      tempTextArea.value = user.dsp.dsp_id;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      try {
        document.execCommand('copy');
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
      document.body.removeChild(tempTextArea);
    } else {
      console.error('DSP ID is undefined.');
    }
  }

  const triggerDownloadQRCode = (size) => {
    const qrContainer = document.getElementById('qr-container');
    const svgElement = qrContainer.querySelector('svg');

    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext('2d');

    const img = new Image();
    img.onload = function () {
      ctx.drawImage(img, 0, 0, size, size);

      const pngUrl = canvas.toDataURL('image/png');

      const a = document.createElement('a');
      a.href = pngUrl;
      a.download = 'qr_code.png';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

    const svgXml = new XMLSerializer().serializeToString(svgElement);
    img.src = 'data:image/svg+xml;base64,' + btoa(svgXml);

  };

  const updateDspSettings = async (settings) => {
    try {
      Loading.set(true);
      const updatedSetting = await updateDSPSettingsFun(settings);
      setUser({ ...user, dsp: { ...user.dsp, settings: settings } })
      Loading.set(false);
      Popup.alert("Success", updatedSetting?.message, "SUCCESS");
    } catch (err) {
      Loading.set(false);
      console.error("Error updating DSP settings:", err);
      Popup.alert("Error", err.errorMessage || "An error occurred while updating DSP settings", "ERROR");
    }
  }

  const handleProfilePicEdit = (file) => {
    let userDetails = { ...user, profile_url: file };
    var formData = new FormData();
    formData.append("profilePic", userDetails?.profile_url, "[PROXY]");
    Loading.set(true);
    uploadProfilePic(formData).then((res) => {
      setUser(() => ({ ...user, profile_url: res.profileUrl }))
      Popup.alert("Profile Picture", "Profile picture updated suceessfully ", 'SUCCESS')
      Loading.set(false);
    }).catch((err) => {
      Popup.alert("Profile Picture", "Something went wrong while updating profile picture ", 'ERROR')
      Loading.set(false);
    })
  }

  const handleCompanyPicEdit = (file) => {
    var formData = new FormData();
    formData.append("image", file, "[PROXY]");
    Loading.set(true);
    uploadCompanyLogo(formData).then((res) => {
      setUser((prev) => ({ ...prev, dsp: { ...prev.dsp, logo_url: res.logo_url } }))
      Popup.alert("Company logo", "Company logo updated suceessfully ", 'SUCCESS')
      Loading.set(false);
    }).catch((err) => {
      console.log("err", err);
      Popup.alert("Company logo", err || "Something went wrong while updating Company logo  ", 'ERROR')
      Loading.set(false);
    })
  }

  const triggerProfilePicUpload = () => {
    if (ProfilePicRef.current) {
      ProfilePicRef.current.click();
    }
  };

  const triggerComapanyLogoUpload = () => {
    if (companyLogoRef.current) {
      companyLogoRef.current.click();
    }
  };


  useEffect(() => {
    document.title = "BhuMeet - Profile";
    if (!user) {
      Loading.set(true)
      login().then((user) => {
        dispatch(setUserReducer(user))
        setUser(user);
        Loading.set(false)
      }).catch(err => {
        Loading.set(false)
        console.log(err);
        Popup.alert("Error", err.errorMessage, "ERROR")
      });
    }
  }, []);

  return user && (user.dsp?.is_verified || user?.is_pdrl_admin) ?
    <>
      {isNameEdit && <EditName
        user={user}
        open={isNameEdit}
        onClose={() => {
          setIsNameEdit(false)
        }}
        onUserUpdate={(user) => {
          setUser(user)
          dispatch(setUserReducer(user))
          setIsNameEdit(false)
        }} />}

      {isPhoneEdit &&
        <EditPhone
          user={user}
          open={isPhoneEdit}
          onClose={() => {
            setIsPhoneEdit(false)
          }}
          onUserUpdate={(user) => {
            setUser(user)
            dispatch(setUserReducer(user))
            setIsPhoneEdit(false)
          }} />}

      {isEmailEdit && <EditEmail
        user={user}
        open={isEmailEdit}
        onClose={() => {
          setIsEmailEdit(false)
        }}
        onUserUpdate={(user) => {
          setUser(user)
          dispatch(setUserReducer(user))
          setIsEmailEdit(false)
        }} />}

      {user.dsp.is_active && <>
        {isCompanyNameEdit && <EditCompanyName
          user={user}
          open={isCompanyNameEdit}
          onClose={() => {
            setCompanyNameEdit(false)
          }}
          onUserUpdate={(company) => {
            setUser((prev) => ({
              ...prev,
              dsp: { ...prev.dsp, name: company.name }
            }))
            dispatch(setUserReducer({
              ...user,
              dsp: { ...user.dsp, name: company.name }
            }))
            setCompanyNameEdit(false)
          }} />}

        {isCompanyAddEdit && <EditCompanyAdd
          user={user}
          open={isCompanyAddEdit}
          onClose={() => {
            setCompanyAddEdit(false)
          }}
          onUserUpdate={(company) => {
            setUser((prev) => ({
              ...prev,
              dsp: { ...prev.dsp, address: company.address }
            }))
            dispatch(setUserReducer({
              ...user,
              dsp: { ...user.dsp, address: company.address }
            }))
            setCompanyAddEdit(false)
          }} />}
      </>}

      {isChangingPassword && <ChangePassword
        open={isChangingPassword}
        onClose={() => {
          setIsChangingPassword(false)
        }} />}

      {isShareAppLinkPopup && <ShareAppLinkPopup
        open={isShareAppLinkPopup}
        onClose={() => {
          setIsdShareAppLinkPopup(false)
        }} />}

      <MainContentWrapper
        containerStyle={{ position: "relative" }}
      >
        <div style={{ width: "100%", backgroundImage: `url(${profileBG})`, backgroundRepeat: "no-repeat", height: "165px", padding: "20px 25px", color: "white", borderRadius: "5px" }}>
          <div style={{ fontWeight: "700", fontSize: "19px" }}>
            My Profile
          </div>
        </div>
        <div style={{ position: "absolute", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", top: "130px", left: "0", gap: "20px" }}>
          <DetailsWrapper>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", height: "143px", background: "#EAEAEA", alignItems: 'center', borderRadius: "8px", padding: "0px 35px 0px 50px" }}>
              <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                {loading ?
                  <LoaderComponent width={"72px"} height={"72px"} /> :
                  <div className="profile_pic_wrapper  ">
                    <img src={user.profile_url != '' && user.profile_url != undefined ? user.profile_url : `https://ui-avatars.com/api/?background=727272&color=fff&name=${user.name.split(" ").join("+")}`} alt="Profile pic" style={{ borderRadius: "50%", width: '90px', height: '90px', objectFit: "cover" }} />
                    <div >
                      <div onClick={triggerProfilePicUpload} className="absolute  bottom-[3px] right-[0px] cursor-pointer">
                        <img src={cameraIcon} alt={"profilepic"} width={28} />
                      </div>
                      <div className="hidden">
                        <FileUploader
                          fileInputRef={ProfilePicRef}
                          uploaderId={"profilePic"}
                          placeholder={"profilePic"}
                          onFileSelect={handleProfilePicEdit}
                          accept={"image/jpeg, image/jpg, image/png"}
                          onClear={() => { }}
                        />
                      </div>
                    </div>
                  </div>
                }
                <div>
                  <Tippy content={user.name}>
                    <p style={{ fontWeight: "500", fontSize: "20px" }}>
                      {user.name.length > 10 ? user.name.substring(0, 20) + '...' : user.name}
                    </p>
                  </Tippy>
                  {user.is_admin &&
                    <p style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB, fontSize: "18px", fontWeight: "500" }}>
                      Admin
                    </p>
                  }
                </div>
              </div>
              <EditButton
                onClickHandler={() => {
                  setIsNameEdit(true)
                }}
                style={{ marginBottom: "60px" }}
              />
            </div>
            <div style={{ padding: "35px", display: "flex", flexDirection: 'column', gap: '40px' }}>
              <div>
                <p style={{ paddingBottom: "20px", fontWeight: "600", color: "#3b3b3b", fontSize: '16px' }}>
                  Contact Details
                </p>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 8fr 0fr", marginLeft: "25px", alignItems: "center", rowGap: "10px", color: '#1e1e1e' }}>
                  <SideIcon
                    icon={phone}
                  />
                  <div style={{ fontWeight: "500", fontSize: '12px', display: "flex", gap: "10px", marginLeft: "10px", alignItems: "center" }}>
                    <span style={{ fontSize: "16px", fontWeight: '500' }}>
                      {user.phone}
                    </span>
                    {user.dsp?.is_verified &&
                      <img src={verify} alt="verify" style={{ width: '20px' }} />
                    }
                  </div>
                  <EditButton
                    onClickHandler={() => {
                      setIsPhoneEdit(true)
                    }}
                  />
                  <SideIcon
                    icon={envelope}
                  />
                  <div style={{ fontWeight: "500", fontSize: '12px', display: "flex", gap: "10px", marginLeft: '10px' }}>
                    <span style={{ fontSize: "16px", fontWeight: '500' }}>
                      {user.email}
                    </span>
                    {user.dsp?.is_verified &&
                      <img src={verify} alt="verify" style={{ width: '20px' }} />
                    }
                  </div>
                  {/* <EditButton
                    onClickHandler={() => {
                      setIsEmailEdit(true)
                    }}
                  /> */}
                </div>
              </div>
              <div>
                <p style={{ paddingBottom: "20px", fontWeight: "600", color: "#3b3b3b", fontSize: '16px' }}>
                  Documents
                </p>
                <div style={{ display: "flex", gridTemplateColumns: "1fr 1fr 9fr", marginLeft: "25px" }}>
                  <div style={{ fontWeight: "500", color: "#3b3b3b", fontSize: '16px' }}>
                    Identity
                  </div>
                  <div style={{ fontWeight: "500", fontSize: '16px', color: 'black', margin: '0px 10px' }}>:</div>
                  <div style={{ fontWeight: "500", fontSize: '16px', color: '#1e1e1e' }}>
                    My Aadhar.png
                  </div>
                </div>
              </div>
              <div>
                <p style={{ paddingBottom: "20px", fontWeight: "600", color: "#3b3b3b", fontSize: '16px', }}>
                  Security
                </p>
                <div style={{ display: "flex", marginLeft: "25px", gap: "25px", alignItems: "center" }}>
                  <SideIcon
                    icon={lockSimple}
                    style={{ padding: "6", width: "32px", height: '32px', }}
                  />
                  <div style={{ fontWeight: "600", fontSize: '16px', color: GLOBAL_STYLES.FONT_COLOR_HEADER, cursor: "pointer", }} onClick={() => { setIsChangingPassword(true) }}>
                    Change Password
                  </div>
                </div>
              </div>
            </div>
          </DetailsWrapper>
          {!user.is_pdrl_admin &&
            <DetailsWrapper>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%", height: "143px", background: "#EAEAEA", alignItems: 'center', borderRadius: "8px", padding: "0px 35px 0px 50px" }}>
                <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                  {loading ?
                    <LoaderComponent width={"72px"} height={"72px"} /> :
                    <div className="profile_pic_wrapper">
                      <img src={(user?.dsp?.logo_url !== '' && user?.dsp?.logo_url != null) ? user?.dsp?.logo_url : `https://ui-avatars.com/api/?background=727272&color=fff&name=${user?.dsp?.name.split(" ").join("+")}`} alt="Profile pic" style={{ borderRadius: "50%", width: '90px', height: '90px', objectFit: "cover" }} />
                      <div >
                        <div onClick={triggerComapanyLogoUpload} className="img_container">
                          <img src={cameraIcon} alt={"companylogo"} width={28} />
                        </div>
                        <div className="hidden">
                          <FileUploader
                            fileInputRef={companyLogoRef}
                            uploaderId={"companylogo"}
                            placeholder={"companylogo"}
                            onFileSelect={handleCompanyPicEdit}
                            accept={"image/jpeg, image/jpg, image/png"}
                            onClear={() => { }}
                          />
                        </div>
                      </div>
                    </div>
                  }
                  <div>
                    <Tippy content={user.dsp.name}>
                      <p style={{ fontWeight: "500", fontSize: "20px" }}>
                        {user.dsp.name.length > 11 ? user.dsp.name.substring(0, 11) + '...' : user.dsp.name}
                      </p>
                    </Tippy>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", minWidth: "120px", marginTop: "2px" }}>
                      <p style={{ fontSize: "12px", fontWeight: "600", color: "rgb(59, 59, 59)" }}>DSP Id :<span style={{ marginLeft: "2px", fontSize: "12px", fontWeight: 400, color: "#3c3c3c" }}>{user.dsp.dsp_id}</span></p>
                      <div
                        onClick={copyDspId}
                        style={{
                          width: "14px",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            opacity: copied ? "1" : "0.5"
                          }}
                          src={copied ? copyDone : copybtn}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {user.is_admin && <EditButton
                  onClickHandler={() => {
                    setCompanyNameEdit(true)
                  }}
                  style={{ marginBottom: "60px" }}
                />}

              </div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div style={{ padding: "35px", display: "flex", flexDirection: 'column', gap: window.innerWidth <= 1366 ? "40px" : '70px' }}>
                  <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "flex-start" }}>
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '25px' }}>
                      <SideIcon
                        icon={mapPin}
                        iconsStyle={{ width: '12px' }}
                      />
                      <div style={{ fontWeight: "500", fontSize: '16px', color: '#1e1e1e', height: '24px' }}>
                        {user?.dsp?.address ? reduceLongName(user.dsp.address, 35) : "-"}
                      </div>
                    </div>
                    {user.is_admin && <EditButton
                      onClickHandler={() => {
                        setCompanyAddEdit(true)
                      }}
                    />}
                  </div>
                  <div id="qr-container" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "-webkit-fill-available" }}>
                    <div style={{ display: 'flex', gap: '15px', }}>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                        <QRCodeSVG
                          value={`https://dsmsapp?dspId=${user?.dsp?.id}`}
                          height={window.innerWidth <= 1366 ? "120px" : "206px"}
                          width={window.innerWidth <= 1366 ? "120px" : "202px"}
                        />
                        <div
                          style={{
                            cursor: "pointer",
                            marginTop: "15px",
                            fontSize: "16px",
                            fontWeight: "500",
                            color: '#3b3b3b'
                          }}
                          onClick={() => triggerDownloadQRCode(5120)}
                        >
                          Download DSP QR Code
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "2px" }}>
                  <hr style={{ margin: '0px' }} />
                  <div style={{ display: 'flex', alignItems: 'center', padding: '20px 30px', justifyContent: "space-between" }}>
                    <div>
                      <div style={{ fontSize: "16px", fontWeight: '500', marginBottom: '5px' }}>
                        OTP for request completion
                      </div>
                      <div style={{ fontSize: "10px", fontWeight: "400", width: '383px' }}>
                        After enabling the two factor authentication , pilot have to put the OTP which we will send to customer and then pilot can mark request as completed
                      </div>
                    </div>
                    <div className="header_toggle flex flex-col items-end gap-[2px]" style={{ cursor: user?.is_admin ? "pointer" : "not-allowed" }}>
                      <Toggle
                        height={"px"}
                        icons={false}
                        checked={user?.dsp?.settings?.isRequstComplate2FAEnabled}
                        onChange={(e) => {
                          if (user?.is_admin) {
                            const settings = {
                              isRequstComplate2FAEnabled: e.target.checked
                            }
                            updateDspSettings(settings)
                          }
                        }}
                        disabled={!user.is_admin}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </DetailsWrapper>}
        </div>
      </MainContentWrapper>
    </> : !user?.dsp || (user?.dsp?.is_verified) ? <></> : <ProfileSetup />;
};

export default Profile;
