import { getDSPId } from "../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../appConstants";
import { getToken } from "./authentication";

export const getDSPDrone = (droneId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + "/dsp/drones/" + droneId,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data.drone);
            } else {
                reject(
                    data?.message || "Something went wrong while getting drone."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting drone.");
        }
    });
};

export const getDSPDrones = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + "/dsp/drones",
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data.drones);
            } else {
                reject(
                    data?.message || "Something went wrong while getting drones."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting drones.");
        }
    });
};

export const addDSPDrone = (drone) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + "/dsp/drones",
                {
                    method: "POST",
                    body: JSON.stringify({ drone }),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(data?.message || "Something went wrong while adding drone.");
            }
        } catch (error) {
            reject("Something went wrong while adding drone.");
        }
    });
};

export const updateDSPDrone = (drone) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + "/dsp/drones",
                {
                    method: "PUT",
                    body: JSON.stringify({ drone }),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while updating location."
                );
            }
        } catch (error) {
            reject("Something went wrong while updating location.");
        }
    });
};

export const checkUINAlreadyExist = (uin) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + "/dsp/drones/checkUIN/" + uin,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve({ drone: data.drones, status: response.status });
            } else {
                resolve({ drone: data.drones, status: response.status });
            }
        } catch (error) {
            reject("Something went wrong while checking UIN existance.");
        }
    });
};

export const deleteDSPDrone = (droneId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                `${SERVER_URLS.BACKEND_SERVER}/dsp/drones/` + droneId,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while deleting drone"
                );
            }
        } catch (error) {
            reject("Something went wrong while while deleting drone");
        }
    });
};


export const getAllDSPDroneUAS = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                `${SERVER_URLS.BACKEND_SERVER}/dsp/drones/drone_uas/all`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            const data = await response.json();
            if (response.status === 200) {
                resolve(data?.drone_uas_all);
            }
            else {
                reject(
                    data?.message || "Something went wrong while fetching drone uas"
                )
            }
        } catch (error) {
            reject("Something went wrong while fetching drone uas")
        }
    })
}

export const getDSPDroneUAS = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                `${SERVER_URLS.BACKEND_SERVER}/dsp/drones/drone_uas/${id}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            const data = await response.json();
            if (response.ok) {
                resolve(data?.drone_uas);
            }
            else {
                reject(
                    data?.message || "Something went wrong while fetching drone uas"
                )
            }
        } catch (error) {
            reject("Something went wrong while fetching drone uas")
        }
    })
}

export const addDSPDroneUAS = (droneInfo) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                `${SERVER_URLS.BACKEND_SERVER}/dsp/drones/drone_uas/`,
                {
                    method: "POST",
                    body: JSON.stringify(droneInfo),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            const data = await response.json();
            if (response.ok) {
                resolve(data?.drone_uas);
            }
            else {
                reject(
                    data?.message || "Something went wrong while adding drone uas"
                )
            }
        } catch (error) {
            reject("Something went wrong while adding drone uas")
        }
    })
}


export const getAllManufacturers = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                `${SERVER_URLS.BACKEND_SERVER}/dsp/manufacturer`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            }
            else {
                reject(
                    data?.message || "Something went wrong while fetching drone manufacturers"
                )
            }
        } catch (error) {
            reject("Something went wrong while fetching drone manufacturers")
        }
    })
}

export const getAllDronesbyUAS = (uasId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                `${SERVER_URLS.BACKEND_SERVER}/dsp/drones/uas/${uasId}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            const data = await response.json();
            if (response.ok) {
                resolve(data.droneUasList);
            }
            else {
                reject(
                    data?.message || "Something went wrong while fetching drone uas"
                )
            }
        } catch (error) {
            reject("Something went wrong while fetching drone uas")
        }
    })
}

export const updateDSPDroneUAS = async (modelId, model) => {
    try {
        const token = getToken();
        const res = await fetch(
            `${SERVER_URLS.BACKEND_SERVER}/dsp/drones/drone_uas/${modelId}`,
            {
                method: "PUT",
                body: JSON.stringify( model ),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        )

        const data = await res.json();
        if (!res.ok) {
            throw new Error(data?.message || "Something went wrong while updating drone UAS");
        }
        return data;
    } catch (err) {
        console.log("err",err);
        throw new Error(err.message || "Something went wrong while updating drone UAS");
    }
}


export const deleteDroneUAS = async (modelId) => {
    try {
        const token = getToken();
        const res = await fetch(
            `${SERVER_URLS.BACKEND_SERVER}/dsp/drones/drone_uas/${modelId}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        const data = await res.json();
        if (!res.ok) {
            throw new Error(data?.message || "Something went wrong while deleting drone UAS");
        }
        return data;
    } catch (err) {
        console.log("err",err);
        throw new Error(err.message || "Something went wrong while deleting drone UAS");
    }
}

