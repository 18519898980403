import React, { useEffect, useState } from 'react';
import dateFormat from 'dateformat';
import './TableView.css'
import { SortComponent } from '../SortComponent';
import TableViewPagination from './TableViewPagination';
import { DeleteComponentTable } from '../Menu';
import LoaderComponent from '../LoaderComponent';
import NotFounds from '../NotFounds';
import { useFirstRender } from '../firstRender';
import noRequest from '../../assets/no-request.svg'

const TableView = ({ columns, data, tableHeading, isLoading, showSrNo, count, onStateChange, onClickRow, activities, hidePagination, pageNumber, onClickLink, loadingHeight, pageSize, notFoundMsg, tableContainerHeight, srNoColumnWidth }) => {
    const firstRender = useFirstRender();
    const [tableData, setTableData] = useState(data)
    const [filteredData, setFilteredData] = useState([])
    const [onTableStateChange, setOnTableStateChange] = useState({
        pageSize: pageSize || 15,
        pageNumber: Number(pageNumber) || 1,
        orderBy: undefined,
        orderType: undefined
    })
    let srNo = (onTableStateChange.pageNumber - 1) * onTableStateChange.pageSize

    const filterTableData = () => {
        const data = tableData?.map(row => {
            const formattedRow = {};
            Object.keys(row).forEach(key => {

                if (columns.some(column => column.accessor === key)) {
                    formattedRow[key] = row[key];
                }
                else {
                    formattedRow[key] = "-";
                }
                if (key === 'color') formattedRow[key] = row[key];
            });
            return formattedRow;
        });
        setFilteredData(data)
    }

    useEffect(() => {
        filterTableData()
    }, [tableData])

    useEffect(() => {
        setTableData(data)
    }, [data])

    useEffect(() => {
        if (!firstRender)
            setOnTableStateChange(prev => ({ ...prev, pageNumber: Number(pageNumber) || 1 }))
    }, [pageNumber])

    useEffect(() => {
        if (!firstRender) {
            onStateChange(onTableStateChange)
        }
    }, [onTableStateChange])

    return (
        <div className={'reusable-table-view-container'} style={{ height: tableContainerHeight || '100%' }} >
            <div className={tableHeading?.length > 0 ? 'reusable-table-title-container' : 'reusable-table-no-title-container'}>
                {tableHeading && <div className='reusable-table-title'> {tableHeading} </div>}
                {<div className='reusable-table-title'> ({count})</div>}
            </div>

            <div className={`reusable-table-containar scroll-container`} >
                <div style={{
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: 'space-between',
                }}>
                    <table className={`reusable-table-view `}>
                        {showSrNo && <col style={{ width: srNoColumnWidth ? `${srNoColumnWidth}%` : '5%' }} />}
                        {columns.map((column, columnIndex) => {
                            return <col key={columnIndex} style={{ width: column.width || 'auto' }} />
                        })}
                        {activities && <col style={{ width: '5%' }} />}
                        {<tr className="reusable-table-view-header-row">
                            {showSrNo && <th key={0}>Sr No.</th>}
                            {columns.map((column, columnIndex) => {
                                return <th key={columnIndex} style={{ textAlign: column.isCenter ? "center" : "left" }} >{column.Header}
                                    {column.isSortable && <SortComponent
                                        style={{ marginLeft: '5px' }}
                                        sortAscending={() => { setOnTableStateChange({ ...onTableStateChange, orderBy: column.accessor, orderType: 'ASC' }) }}
                                        sortDecending={() => { setOnTableStateChange({ ...onTableStateChange, orderBy: column.accessor, orderType: 'DESC' }) }}
                                        sortASC={false}
                                    />}</th>
                            })}
                            {(activities) && filteredData.length > 0 && <th>Activity</th>}
                        </tr>}
                        {isLoading ?
                            <tr align="center" >
                                <td colSpan={showSrNo && activities ? columns.length + 2 : activities || showSrNo ? columns.length + 1 : columns.length} style={{ height: loadingHeight || "75vh", background: "#FCFCFC" }}>
                                    <div style={{ position: "relative" }}>
                                        <LoaderComponent height="9%" width="3%" imgMargin={"0px 0px 0px 80px"} />
                                    </div>
                                </td>
                            </tr> :
                            filteredData?.length === 0 ?
                                <tr className="reusable-table-view-data-row" align="center" >
                                    <td colSpan={showSrNo ? columns.length + 1 : columns.length} style={{ height: loadingHeight || "228px" }}>
                                        <NotFounds imgUrl={noRequest} msg={notFoundMsg || "No data available!"} hideBtn imgStyle={{ width: "100px" }} />
                                    </td>
                                </tr> :
                                <>
                                    {filteredData?.map((row, rowIndex) => (
                                        <tr
                                            key={rowIndex}
                                            onClick={() => {
                                                if (onClickRow) onClickRow(tableData[rowIndex])
                                            }}
                                            className={`reusable-table-view-data-row-link row-hover`}
                                            style={{ cursor: onClickRow ? "pointer" : "auto", opacity: tableData[rowIndex]?.opacity || '1' }}
                                        >
                                            {showSrNo && <td key={rowIndex + 1}>{++srNo}</td>}
                                            {columns.map((column, columnIndex) => {
                                                const accessor = column.accessor;
                                                return <td style={{
                                                    maxWidth: '0px',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: column.isLink ? "unset" : 'ellipsis',
                                                    cursor: onClickRow ? "pointer" : "default",
                                                    color: column.showColor ? row['color'] : 'default',
                                                    fontWeight: column.showColor ? 600 : "",
                                                }} key={columnIndex} >
                                                    {column.isDate && row[accessor] != null ? dateFormat(row[accessor], column.format || "dd/mm/yyyy hh:MM TT") :
                                                        column.isLink ?
                                                            <img src={row[accessor].img} style={{ height: "20px", position: "relative", zIndex: 1, top: column.isCenter ? "25%" : "unset", left: column.isCenter ? "50%" : "unset", transform: column.isCenter ? "translate(-50%,-50%)" : "unset" }}
                                                                onClick={(e) => { e.stopPropagation(); onClickLink(tableData[rowIndex]) }} />
                                                            : row[accessor] || '-'}
                                                </td>;
                                            })}
                                            {activities && <td>
                                                <DeleteComponentTable
                                                    style={{ marginRight: '10px' }}
                                                    onClickDelete={activities.onClickDelete ? () => activities.onClickDelete(tableData[rowIndex]) : undefined}
                                                    disableDelete={activities.disableDelete}
                                                    canEdit={activities.canEdit}
                                                    onClickEdit={activities.onClickEdit ? () => activities.onClickEdit(tableData[rowIndex]) : undefined}
                                                    canManage={activities.canManage}
                                                    onClickManage={activities.onClickManage ? () => activities.onClickManage(tableData[rowIndex]) : undefined}
                                                    canRename={activities.canRename}
                                                    onClickRename={activities.onClickRename ? () => activities.onClickRename(tableData[rowIndex]) : undefined}
                                                    canExport={activities.canExport}
                                                    onClickExport={activities.onClickExport ? () => activities.onClickExport(tableData[rowIndex]) : undefined}
                                                    canDuplicate={activities.canDuplicate}
                                                    onClickDuplicate={activities.onclickDuplicate ? () => activities.onclickDuplicate(tableData[rowIndex]) : undefined}
                                                    canDownload={activities.canDownload}
                                                    onClickDownload={activities.onClickDownload ? () => activities.onClickDownload(tableData[rowIndex]) : undefined}
                                                /> </td>}
                                        </tr>
                                    ))}
                                </>
                        }
                    </table>
                </div>

            </div >

            {(hidePagination || filteredData?.length === 0) ?
                <></> :
                <div>
                    <TableViewPagination
                        isLoading={isLoading}
                        currentPage={Number(pageNumber) || onTableStateChange.pageNumber}
                        totalCount={count || 0}
                        pageSize={onTableStateChange.pageSize}
                        padding="10px 5px"
                        onPageChange={(page) => {
                            setOnTableStateChange({ ...onTableStateChange, pageNumber: page })
                        }}
                    />
                </div>
            }
        </div>
    );
};

export default TableView;