import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';  // Important for tree-shaking
import Loader from '../ReusableComponents/Loader';
import { getRequests } from '../apis/requests';
import { AEROMEGH_SERVICES, GLOBAL_STYLES, STATUS } from "../appConstants";
import { PDRL_ADMIN_ROUTES, ROUTES } from "../routes";
import ReactLoading from "react-loading";
import NotFounds from '../ReusableComponents/NotFounds';
import noRequest from '../assets/no-request.svg'
import 'chart.js/auto';  // Important for tree-shaking
import TableView from '../ReusableComponents/TableView/TableView';
import { useNavigate } from "react-router-dom";


const RecentRequests = ({ user }) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [hasData, setHasData] = useState(true)
    const [requests, setRequests] = useState([])

    const applyStatusColor = (reqArr) => {
        let newArr = reqArr.map(req => {
            if (req.status == STATUS.PLACED) return { ...req, status: STATUS.PLACED, color: GLOBAL_STYLES.STATUS.TEXT.PLACED }
            if (req.status == STATUS.INPROGRESS) return { ...req, color: GLOBAL_STYLES.STATUS.TEXT.INPROGRESS }
            if (req.status == STATUS.COMPLETED) return { ...req, color: GLOBAL_STYLES.STATUS.TEXT.COMPLETED }
            if (req.status == STATUS.PAID) return { ...req, color: GLOBAL_STYLES.STATUS.TEXT.PAID }
            if (req.status == STATUS.ACCEPTED) return { ...req, color: GLOBAL_STYLES.STATUS.TEXT.ACCEPTED }
            if (req.status == STATUS.CANCELLED) return { ...req, color: GLOBAL_STYLES.STATUS.TEXT.CANCELLED }
            if (req.status == STATUS.RESCHEDULED) return { ...req, color: GLOBAL_STYLES.STATUS.TEXT.RESCHEDULED }
            if (req.status == STATUS.OUT_FOR_SERVICE) return { ...req, color: GLOBAL_STYLES.STATUS.TEXT.OUT_FOR_SERVICE }
            return req
        })
        setRequests(newArr)
    }

    const getRequestsAPI = () => {
        setLoading(true)
        getRequests(user.userId)
            .then(async (res) => {
                if (res.requests.length > 5) {
                    let reqArr = res.requests.slice(0, 5)
                    applyStatusColor(reqArr)
                }
                else applyStatusColor(res.requests)
                setLoading(false)
            })
            .catch((err) => {
                console.log("err : ", err);
                setLoading(false)
            });
    };

    useEffect(() => {
        getRequestsAPI()
    }, [])

    return (<div style={{ height: "100%", width: "100%", position: "relative" }}>
        <div style={{ color: "#3B3B3B", fontSize: "13px", padding: "17px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div className="dashboard-cards-heading" >Recent Requests</div>
            {requests.length > 0 ? <div className="dashboard-cards-heading" onClick={() => {
                navigate(ROUTES.MYREQUESTS);
            }} style={{ cursor: "pointer", color: "#0F5EFF" }}> View all</div> : <div />}
        </div>

        {
            loading ?
                <ReactLoading
                    className="loading"
                    type={"spin"}
                    color={GLOBAL_STYLES.BG_ACTIVE}
                    width={"6%"}
                /> : hasData && requests.length > 0 ?
                    <TableView
                        isLoading={loading}
                        showSrNo
                        onStateChange={(changedState) => {
                            console.log("onStateChange called :", changedState);
                        }}
                        onClickRow={(request) => {
                            console.log("onClickRow called");
                            navigate(`${ROUTES.REQUESTDETAILS}`, { state: { request: request } })
                        }}
                        columns={[
                            { Header: "Location", accessor: "location_name", },
                            { Header: "Service", accessor: "type", },
                            { Header: "Customer Name", accessor: "user_name", },
                            { Header: "Status", accessor: "status", showColor: true },
                        ]}
                        data={requests}
                        hidePagination
                        srNoColumnWidth={"13"}
                    /> :
                    <div style={{ height: "calc(100% - 50px)" }}>
                        <NotFounds imgUrl={noRequest} msg={"You haven't received any request yet !"} hideBtn imgStyle={{ width: "100px" }} />
                    </div>

        }</div >);
};

export default RecentRequests;
