import React, { useEffect, useState } from "react";
import { deleteDronePilot, getDronePilots } from "../../apis/dronePilots";
import { GLOBAL_STYLES } from "../../appConstants";
import { Link } from "react-router-dom";
import { Button, OptionMenu } from "../../Components/Helper";
import Plus from "../../assets/Plus.svg";
import Popup from "../../ReusableFunctions/Popup";
import NotFounds from "../../ReusableComponents/NotFounds";
import Loading from "../../ReusableFunctions/Loader";
import { ROUTES } from "../../routes";
import more from "../../assets/more.png"
import verify from "../../assets/greenTick.png"
import location from "../../assets/location.png"
import call from "../../assets/call.png"
import idCard from "../../assets/idCard.png"
import orders from "../../assets/orders.png"
import { getLocation } from "../../apis/locations";
import { reduceLongName } from "../../ReusableFunctions/reusableFunctions";
import pencil from "../../assets/edit.svg"
import dustbin from "../../assets/Trash.svg"
import AddDronePilot from "./SubComponents/AddDronePilot";
import calender from "../../assets/Calendar.png"
import calenderActive from "../../assets/calendarActive.png"
import lines from "../../assets/lines.png"
import lineActive from "../../assets/lineActive.png"
import MainContentWrapper from "../../Components/MainContentWrapper";
import Tippy from "@tippyjs/react";


const DronePilots = () => {
  const [pilots, setPilots] = useState();
  const [activityPopup, setActivityPopup] = useState({
    id: null,
    isOpen: false
  })
  const [pilotId, setPilotId] = useState(null);
  const [openEditPilot, setOpenEditPilot] = useState(false)
  const [indianStates, setIndianStates] = useState([]);
  const [isCalenderView, setIsCalenderView] = useState(false)
  const [isCardView, setIsCardView] = useState(true)
  const getPilotLocation = (lId) => {
    return new Promise((resolve, reject) => {
      getLocation(lId)
        .then((res) => {
          resolve(res.location.name)
        })
        .catch((err) => {
          console.log(err);
          Popup.alert("Error", err, "ERROR");
        });
    })
  }
  const openActicityPopup = (id) => {
    setActivityPopup((prev) => ({
      id: id,
      isOpen: prev.id !== id || !prev.isOpen, // Close the current popup and open the clicked row
    }));
  }
  const handleOnPilotAdd = async (pilot) => {
    if (pilotId) {
      const updatedPilot = await Promise.all(pilots.map(async (p) => {
        if (p.id === pilotId) {
          const updatePilot = {
            ...p,
            ...pilot
          }
          return updatePilot
        }
        else return p;
      }))
      setPilots(updatedPilot)
    }
    else {
      setPilots((prev) => ([
        pilot,
        ...prev
      ]))
    }
  }
  const getStates = async () => {
    try {
      const country = { "country": "India" }
      let res = await fetch("https://countriesnow.space/api/v0.1/countries/states", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(country)
      })
      let states = await res.json()
      setIndianStates(() => states.data.states)
    } catch (err) {
      console.log("ERROR :", err);
    }
  }
  const deletePilot = (id) => {
    Popup.alert("Drone Pilot", "Are you sure, you want to delete this drone pilot?", "DELETE", () => {
      Loading.set(true);
      deleteDronePilot(id)
        .then((res) => {
          Loading.set(false);
          const afterDeletePilots = pilots.filter(p => p.id !== id)
          setPilots(afterDeletePilots)
          Popup.alert("Drone Pilot", "Drone pilot successfully deleted", "SUCCESS")
        })
        .catch((err) => {
          console.log(err);
          Popup.alert("Error", err, "ERROR");
        });
    });
  }
  useEffect(() => {
    Loading.set(true);
    document.title = "BhuMeet - Drone Pilots";
    getDronePilots()
      .then(async (res) => {
        setPilots(res.dronePilots);
        getStates();
        Loading.set(false);
      })
      .catch((err) => {
        console.log(err);
        Loading.set(false);
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  }, []);

  return (
    <MainContentWrapper header={`Pilots (${pilots?.length > 0 ? pilots?.length : 0})`} rightContent={pilots && pilots?.length > 0 &&
      <Button type="submit" icon={Plus} text={"Add New"} onClick={() => {
        setPilotId(null)
        setOpenEditPilot(true)
      }} style={{ padding: "4px, 24px, 4px, 24px", height: "35px", width: "120px", fontSize: "14px", fontWeight: 500 }} />}>
      <div
        style={{
          width: "100%",
          height: "95%",
          display: "flex",
          flexDirection: "column",
          padding: "0px 0px 0px 5px"
        }}
      >
        {openEditPilot &&
          <AddDronePilot
            open={openEditPilot}
            onClose={() => { setOpenEditPilot(false) }}
            pilotId={pilotId || null}
            onPilotAdd={(pilot) => {
              handleOnPilotAdd(pilot)
              setOpenEditPilot(false)
            }}
            indianStates={indianStates}
          />
        }
        {pilots && pilots.length > 0 ? (
          <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill,360px)", gridGap: "40px", marginTop: "6px", overflow: "auto" }}>
            {pilots && pilots?.length > 0 && (pilots?.map((pilot) => (
              <Link to={ROUTES.MYREQUESTS} state={{ isCalenderView: true, pilotId: pilot.id, pilotName: `${pilot.first_name} ${pilot.last_name}` }}>
                <div style={{ padding: "16px 11px", cursor: "pointer", borderRadius: "20px", height: "316px", width: "365px", background: "#FCFCFC", border: "0.1px solid rgba(191, 200, 217, 0.5)" }}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 5px" }}>
                    <div style={{ background: pilot?.is_active ? "#109316" : "#848484", color: "white", padding: "6px 12px", margin: "5px 0px 0px 8px", borderRadius: "8px", fontSize: GLOBAL_STYLES.FONT_SIZE_REGULAR, fontFamily: "Manrope", fontWeight: 600 }}>{pilot?.is_active ? "Active" : "Inactive"}</div>
                    <Tippy offset={[10, -15]} interactive onShown={(instance) => {
                      document.querySelector('[data-tippy-root]').addEventListener('click', event => {
                        instance.hide();
                      })
                    }} className='tippy' trigger='click' arrow={false} content={
                      <OptionMenu
                        isLeft
                        onClickDelete={(e) => {
                          e.preventDefault()
                          deletePilot(pilot.id)
                        }}
                        onClickEdit={(e) => {
                          e.preventDefault()
                          setPilotId(pilot.id)
                          setOpenEditPilot(true)
                          openActicityPopup(pilot.id)
                        }}
                      />
                    } >
                      <img id='menu' src={more} alt='more' style={{ width: "22px", cursor: "pointer" }} onClick={(e) => {
                        e.preventDefault()
                        openActicityPopup(pilot?.id)
                      }} />
                    </Tippy>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "15px", paddingBottom: "32px" }}>
                    <div style={{ position: "relative" }}>
                      <img src={`https://ui-avatars.com/api/?background=04379F&color=fff&name=${pilot?.first_name?.charAt(0) + "" + `${pilot.last_name.charAt(0)}`}`} alt="profile pic" style={{ borderRadius: "50%", width: "84px", height: "84px" }} />
                      <img src={verify} alt="green tick" style={{ position: "absolute", top: "75%", left: "74%", background: "white", borderRadius: "50%" }} />
                    </div>
                    <div style={{
                      color: '#1E1E1E',
                      fontSize: '20px',
                      fontFamily: 'Manrope',
                      fontWeight: 500,
                      wordWrap: 'break-word'
                    }}>
                      {reduceLongName(pilot.first_name && pilot.last_name ? `${pilot.first_name} ${pilot.last_name}` :
                        (pilot.first_name ? pilot.first_name : pilot.last_name ? pilot.last_name : '-'), 20)}
                    </div>
                  </div>
                  <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", paddingLeft: '10px', alignItems: "center", gap: '8px 30px' }}>
                    <div style={{ display: "flex", alignItems: 'center', gap: "12px" }} >
                      <div style={{}}>
                        <img src={location} alt="location" style={{ width: "38px" }} />
                      </div>
                      <div style={{ color: '#3B3B3B', fontSize: '15px', fontFamily: 'Manrope', fontWeight: '500' }}>
                        {pilot.location_name ? reduceLongName(pilot.location_name, 10) : "-"}
                      </div>
                    </div>
                    <div style={{ display: "flex", alignItems: 'center', gap: "12px" }} >
                      <div style={{}}>
                        <img src={call} alt="call" style={{ width: "38px" }} />
                      </div>
                      <div style={{ color: '#3B3B3B', fontSize: '15px', fontFamily: 'Manrope', fontWeight: '500' }}>
                        {pilot.phone || "-"}
                      </div>
                    </div>
                    <div style={{ display: "flex", alignItems: 'center', gap: "12px" }} >
                      <div style={{}}>
                        <img src={idCard} alt="idCard" style={{ width: "38px !important" }} />
                      </div>
                      <div style={{ color: '#3B3B3B', fontSize: '15px', fontFamily: 'Manrope', fontWeight: '500' }}>
                        {pilot.aadhar_number || "-"}
                      </div>
                    </div>
                    <div style={{ display: "flex", alignItems: 'center', gap: "12px" }} >
                      <div style={{}}>
                        <img src={orders} alt="orders" style={{ width: "38px" }} />
                      </div>
                      <div>
                        <div style={{ color: '#3B3B3B', fontSize: '15px', fontFamily: 'Manrope', fontWeight: '500' }}>{pilot.completedRequest ? pilot.completedRequest : "-"}</div>
                        <div style={{ fontSize: "10px", fontWeight: 400, color: "#848484" }}>(Orders Completed)</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            )))}
          </div>
        ) : (
          pilots && pilots.length === 0 && (
            <NotFounds msg={"OOPS, No Pilots Found!"} onBtnClick={() => setOpenEditPilot(true)} />
          )
        )}
      </div>
    </MainContentWrapper>
  );
};

export default DronePilots;
