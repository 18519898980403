import React, { useState, useEffect, useRef } from "react"
import Modal from "../../../../../ReusableComponents/Modal"
import { getAllCrops, getCropsByLocation, isCommonCropsInSync, saveCommonCropCost } from "../../../../../apis/crops"
import { GLOBAL_STYLES, LIMITS } from "../../../../../appConstants"
import Popup from "../../../../../ReusableFunctions/Popup"
import Input from "../../../../../ReusableComponents/Input"
import LoaderComponent from "../../../../../ReusableComponents/LoaderComponent"
import { Button, OptionMenu } from '../../../../../Components/Helper';
import SelectComponent from '../../../../../ReusableComponents/SelectComponent';
import './locationCropCost.css'
import MenuDark from '../../../../../assets/MenuDark.png';
import Check from '../../../../../assets/check1.svg';
import RupeeSign from "../../../../../assets/rupee.png";
import Tippy from '@tippyjs/react';
import Loading from "../../../../../ReusableFunctions/Loader"
const CommonCropPricingPopup = ({ locationId, onRequestClose, sprayingServiceParameters }) => {
    const [loading, setLoading] = useState(true)
    const [crops, setCrops] = useState([])
    const [cropsOptions, setCropsOptions] = useState([])
    const [toBedeleteCrops, setToBedeleteCrops] = useState([]);
    const [activeTooltipId, setActiveTooltipID] = useState(undefined)
    const [editPrice, setEditPrice] = useState("");
    const editInputRef = useRef(null);
    const [newCrop, setNewCrop] = useState({
        crop_name: "",
        cost: "",
        crop_id: "",
    });
    const availableCrops = cropsOptions.filter(
        (option) => !crops.some((crop) => crop.crop_id === option.value)
    );

    const initData = async () => {
        const cropsRes = await getAllCrops()
        const cropsOptions = cropsRes?.crops?.map((crop) => ({
            label: crop.name,
            value: crop.id,
        }))
        if (locationId) {
            const res = await isCommonCropsInSync()
            if (!res.allInSync) {
                Popup.alert("Common Crops",
                    "The crop costs are not synchronized across all locations. This means that some locations have differing crop costs. Do you wish to proceed by clearing all the crops?",
                    "WARNING",
                    async () => {
                        sprayingServiceParameters.is_common_pricing_for_locations = true
                        await saveCommonCropCost([], sprayingServiceParameters);
                        await initData()
                        setLoading(false)
                    },
                    null, null,
                    () => onRequestClose(true)
                )

            } else {
                const cropsOfLocation = await getCropsByLocation(locationId)
                setLoading(false)
                setCropsOptions(() => cropsOptions);
                setCrops(cropsOfLocation.locationcropslist)
            }
        } else {
            setLoading(false)
            setCropsOptions(() => cropsOptions);
            setCrops([])
        }
    }

    const handleCropEdit = (crop_id) => {
        setCrops(prev => prev.map(c => {
            if (c.crop_id == crop_id) return { ...c, isEditable: true };
            else return c
        }))
    }

    const handleOutsideClick = (e, crop_id) => {
        if (e.currentTarget.id === `row-${crop_id}`) {
            setCrops((prevCrops) => (
                prevCrops.map((crop) => (
                    crop.id !== crop_id ? { ...crop, isEditable: false } : crop
                ))
            ))
        }
    }
    const handleSaveCommonCrops = async () => {
        try {
            Loading.set(true)
            sprayingServiceParameters.is_common_pricing_for_locations = true
            await saveCommonCropCost(crops, sprayingServiceParameters)
            Loading.set(false)
            Popup.alert("Crops Saved", "Crops saved successfully", "SUCCESS", () => onRequestClose())
        } catch (e) {
            Loading.set(false)
            Popup.alert("ERROR", "Something went wrong while saving common crops", "ERROR")
        }
    }
    useEffect(() => {
        initData()
    }, [locationId])

    return (
        <>
            <Modal
                header={"Manage Common Pricing"}
                open={true}
                headerContainerStyle={{ padding: "20px 30px" }}
                onClose={() => onRequestClose()}
                closeOnDocumentClick={false}
                contentStyle={{ height: "65%", width: '700px', boxShadow: " 0px 1px 4px 3px #76767626", border: "1px solid #BFC8D9", borderRadius: "8px", position: "relative" }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', padding: "12px 25px", justifyContent: 'space-between', width: '100%', height: 'calc(100% - 65px)' }}>
                    <div style={{ width: "100%", height: "100%", overflow: "auto" }} >
                        {loading ?
                            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                                <LoaderComponent height={'5%'} width={'5%'} />
                            </div>
                            :
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <h5 style={{ color: "#3B3B3B", fontFamily: "Manrope", fontWeight: 500 }}>Add new crop here</h5>
                                <form className='crop_details_wrapper' onSubmit={(e) => {
                                    e.preventDefault()
                                    setCrops((prev) => ([...prev, newCrop]))
                                    setNewCrop((prev) => ({
                                        ...prev,
                                        crop_id: "",
                                        crop_name: "",
                                    }))
                                }}>
                                    <div style={{ display: "flex", width: "100%", height: "85px", marginTop: "10px", justifyContent: "space-between", alignItems: "baseline", }}>
                                        <div style={{
                                            width: "35%",
                                            maxWidth: "35%",
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "4px"
                                        }}>
                                            <div style={{ fontSize: '15px', color: "#3B3B3B", fontWeight: 500 }}>
                                                Crop
                                            </div>
                                            <SelectComponent
                                                controlStyle={{ height: "35px", }}
                                                menuStyles={{ zIndex: "100" }}
                                                menuListStyles={{ maxHeight: "220px" }}
                                                value={newCrop?.crop_id ? { value: newCrop.crop_id, label: newCrop.crop_name } : null}
                                                onChange={(selectedCrop) => {
                                                    setNewCrop((prev) => ({
                                                        ...prev,
                                                        crop_id: selectedCrop.value,
                                                        crop_name: selectedCrop.label,
                                                    }))
                                                }}
                                                isDisabled={loading}
                                                isLoading={loading}
                                                list={availableCrops}
                                                style={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                                            />
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "35%",
                                            maxWidth: "35%",
                                            gap: "4px"
                                        }}>
                                            <label style={{ fontSize: '15px', color: "#3B3B3B", fontWeight: 500 }} >Price/Acre</label>
                                            <Input
                                                maxLength={LIMITS.CROP_PRICE}
                                                type={"text"}
                                                value={newCrop.cost}
                                                style={{ height: "35px" }}
                                                className="text_input_field"
                                                placeholder={"Enter spraying cost "}
                                                name={"cost"}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    value = value.replace(/[^\d]/g, '')
                                                    if (value === '' || parseInt(value) >= 1) {
                                                        setNewCrop((prev) => ({
                                                            ...prev,
                                                            cost: value
                                                        }));
                                                    }
                                                }}
                                            />
                                        </div>

                                        <Button
                                            text={"Save"}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setCrops((prev) => ([...prev, newCrop]))
                                                setNewCrop((prev) => ({
                                                    ...prev,
                                                    crop_id: "",
                                                    crop_name: "",
                                                }))
                                            }}
                                            isDisabled={!newCrop.crop_name || !newCrop.cost}
                                            style={{ width: "20%", height: "35px", margin: "auto 0px" }}
                                        />

                                    </div>

                                    <div style={{}}>
                                        <h5 style={{ color: "#3B3B3B", fontSize: "16px", marginBottom: "5px" }}>Added Crop</h5>
                                        <div className='crops_table_container' >
                                            {loading ?
                                                <div style={{ display: "flex", width: "100%", minHeight: "200px", position: "relative" }}>
                                                    <LoaderComponent width={"6%"} height={"6%"} />

                                                </div>
                                                :
                                                crops.length > 0 ?
                                                    <table className="crops-table">
                                                        <thead width={"100%"}>
                                                            <tr>
                                                                <th>Crop</th>
                                                                <th>Price/Acre</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <div className='table_body'>
                                                            <tbody>
                                                                {crops.map((crop, i) => (
                                                                    <tr id={`row-${crop.id}`} onClick={(e) => handleOutsideClick(e, crop.id)} key={crop.id}  >
                                                                        <td style={{ color: "rgba(0, 0, 0, 1)", fontSize: "14px", }}>{crop.crop_name}</td>
                                                                        <td style={{ color: "rgba(0, 0, 0, 1)", fontSize: "14px" }}>
                                                                            {crop.isEditable ? (
                                                                                <div style={{ display: "flex", width: "100%", justifyContent: "center", marginLeft: "8px", gap: "0.5rem", alignItems: "center" }}>
                                                                                    <div style={{ width: "18px" }}>
                                                                                        <img src={RupeeSign} alt='rupees' width={"18px"} height={"5px"} />
                                                                                    </div>
                                                                                    <input
                                                                                        type="text"
                                                                                        ref={editInputRef}
                                                                                        maxLength={LIMITS.CROP_PRICE}
                                                                                        style={{ width: "50px", borderBottom: "1px solid #0F5EFF", outline: "none" }}
                                                                                        value={editPrice}
                                                                                        onChange={(e) => {
                                                                                            let value = e.target.value;
                                                                                            value = value.replace(/[^\d]/g, '')
                                                                                            if (value === '' || parseInt(value) >= 1) {
                                                                                                setEditPrice(() => value);
                                                                                            }

                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                <>
                                                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                        <div style={{ width: "18px" }}>
                                                                                            <img src={RupeeSign} alt='rupees' width={"18px"} height={"5px"} />
                                                                                        </div>
                                                                                        <span style={{ marginLeft: "8px" }}>{crop.cost}</span>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <div style={{ position: "relative", display: "flex", justifyContent: "center" }}>
                                                                                <Tippy
                                                                                    visible={activeTooltipId === crop.crop_id}
                                                                                    className='tippy'
                                                                                    onClickOutside={() => setActiveTooltipID(null)}
                                                                                    offset={[-15, -5]} interactive
                                                                                    onShown={(instance) => {
                                                                                        document.querySelector('[data-tippy-root]').addEventListener('click', event => {
                                                                                            instance.hide()
                                                                                        })
                                                                                    }}
                                                                                    onShow={instance => {
                                                                                        const el = document.querySelector(".scroll-container")
                                                                                        if (el)
                                                                                            el.addEventListener('scroll', event => {
                                                                                                instance.hide()
                                                                                            })
                                                                                    }}
                                                                                    trigger='click'
                                                                                    arrow={false}
                                                                                    content={
                                                                                        <OptionMenu
                                                                                            isLeft
                                                                                            onClickEdit={() => {
                                                                                                handleCropEdit(crop.crop_id)
                                                                                                setEditPrice(crop.cost);
                                                                                                setActiveTooltipID(false);
                                                                                            }}
                                                                                            onClickDelete={() => {
                                                                                                if (crop.id !== undefined) {
                                                                                                    toBedeleteCrops.push(crop.id)
                                                                                                }
                                                                                                let afterdeleteCrop = crops.filter((c) => c.crop_id !== crop.crop_id);
                                                                                                setCrops(() => afterdeleteCrop);

                                                                                            }}
                                                                                        />
                                                                                    } >
                                                                                    {crop.isEditable ?
                                                                                        <div
                                                                                            style={{ width: "100%", height: "15px", display: "flex", justifyContent: "center", alignItems: "center" }}
                                                                                            onClick={editPrice === "" ? null
                                                                                                : (e) => {
                                                                                                    e.stopPropagation();
                                                                                                    setCrops(prev => prev.map(c => {
                                                                                                        if (c.crop_id == crop.crop_id) return { ...c, cost: editPrice, isEditable: false };
                                                                                                        else return c
                                                                                                    }))
                                                                                                    setEditPrice("");
                                                                                                }}
                                                                                        >
                                                                                            <img id='check' src={Check} alt='check'
                                                                                                style={{ width: "15px", cursor: editPrice === "" ? "not-allowed" : "pointer", opacity: editPrice === "" ? 0.5 : 1 }}
                                                                                            />

                                                                                        </div>
                                                                                        :
                                                                                        <div
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                setActiveTooltipID(crop.crop_id);
                                                                                            }}
                                                                                            style={{ width: "100%", height: "15px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                            <img id='menu' src={MenuDark} alt='more' style={{ width: "15px", cursor: "pointer" }} />

                                                                                        </div>
                                                                                    }

                                                                                </Tippy>
                                                                            </div>

                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </div>
                                                    </table> :
                                                    <div className='no_data_message' >
                                                        <div className='drone-msg'>
                                                            <div style={{ fontSize: "15px", color: GLOBAL_STYLES.FONT_COLOR_SUB, width: "350px", textAlign: "center", fontWeight: 500 }}>You haven't added any crops yet !</div>
                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                    </div>
                                </form >
                            </div>
                        }
                    </div >
                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                        <Button
                            isDisabled={loading}
                            text={"Update"}
                            onClick={handleSaveCommonCrops}
                            style={{ width: "20%", height: "35px", margin: "auto 0px" }}
                        />
                    </div>
                </div>

            </Modal>
        </>
    )
}
export default CommonCropPricingPopup