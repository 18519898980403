const SERVER_URLS = {
  BACKEND_SERVER: process.env.REACT_APP_API_URL,
};

const LIST_VIEW_TYPES = {
  DROPDOWN: "DROPDOWN",
};
const GLOBAL_STYLES = {
  ERROR: "#ff6464",
  ACTIVE: "#39A506",
  INACTIVE_GREY_COLOR: "#848484",
  LOW_INACTIVE_GREY_COLOR: "#f3f3f3",
  FONT_COLOR_SUB: "#848484",
  FONT_COLOR_HEADER: "#0F5EFF",
  BG_INACTIVE: "white",
  BG_ACTIVE: "#0F5EFF",
  FONT_SIZE_REGULAR: "11px",
  FONT_SIZE_MEDIUM: "12px",
  FONT_SIZE_SMALL: "10px",
  FONT_SIZE_BIG_HEADER: "19px",
  FONT_SIZE_MEDIUM_HEADER: "18px",
  FONT_SIZE_SMALL_HEADER: "16px",
  BORDER_COLOR: "#D9D9D9",
  BOX_SHADOW: "rgba(98, 115, 43, 0.1) 0px 0px 8px 2px",
  STATUS: {
    BACKGROUND: {
      COMPLETED: "rgba(0, 166, 136, 1)",
      PLACED: "rgba(255, 145, 15, 0.2) ",
      INPROGRESS: "rgba(178, 160, 0, 0.2)",
      CANCELLED: "rgba(219, 0, 0, 0.2)",
      ACCEPTED: "rgba(15, 94, 255, 0.2)",
      PAID: "rgba(0, 135, 13, 0.2)",
      RESCHEDULED: "rgb(0, 0, 128)",
      OUT_FOR_SERVICE: "rgb(213, 203, 236)",
    },
    TEXT: {
      COMPLETED: "#00A688",
      PLACED: "#FF910F",
      INPROGRESS: "#B2A000",
      CANCELLED: "#DB0000",
      ACCEPTED: "#0F5EFF",
      PAID: "#026B0C",
      RESCHEDULED: "#E27444",
      OUT_FOR_SERVICE: "#12D58F"
    }
  }
};

const IDENTITY_CARD_TYPES = {
  AADHAR_CARD: "aadhar_card",
  PAN_CARD: "pan_card",
};
const AEROMEGH_SERVICES = {
  SPRAYING: "Spraying service",
  SURVEY: "Survey service",
  TRAINING: "Training service",
  INSURANCE: "Insurance service",
};

const STATUS = {
  PLACED: "Placed",
  ACCEPTED: "Accepted",
  RESCHEDULED: "Rescheduled",
  OUT_FOR_SERVICE: "Out for Service",
  INPROGRESS: "InProgress",
  COMPLETED: "Completed",
  PAID: "Paid",
  CANCELLED: "Cancelled"
}

const STATUS_MESSAGES = {
  PLACED: "Service request has been placed.",
  ACCEPTED: "Service request has been accepted.",
  RESCHEDULED: "Service request has been rescheduled.",
  OUT_FOR_SERVICE: "Service request is out for service.",
  INPROGRESS: "Service request is in progress.",
  COMPLETED: "Service has been completed.",
  PAID: "Total amount has been received.",
  CANCELLED: "Service request has been cancelled."
}

const DRONE_CAPACITIES = ["5 ltr", "6 ltr", "8 ltr", "10 ltr", "16 ltr", "20 ltr"];
const DRONE_UIN = [
  "1fe490ad-4f0a-46c6-bf73-d6f86f89db9e",
  "b59494ab-666f-4e50-ae53-578487f0ce94",
  "ae7025f7-c421-40b3-b2fa-fcf61ed795c2",
  "73921c44-be56-406f-8b18-320462b7df71",

]

const DISCOUNT_TYPES = {
  DSP_REQUEST_DISCOUNT: "dsp_request_discount"
}

const DRONE_TYPES = ["eVTOLS", "Hexacopter", "Quadcopters"];
const DRONE_NAMES = {
  eVTOLS: "eVTOLS",
  Hexacopter: "Hexacopter",
  Quadcopters: "Quadcopters"
}
const SERVICE_TYPES = ["Spraying", "Survey"]
const LIMITS = {
  NAME: 50,
  PASSWORD: 14,
  PHONE_NUMBER: 10,
  ADDRESS: 200,
  RATE_PER_ACRE: 1000000,
  RATE_PER_SQ_KM: 1000000,
  CROP_NAME: 20,
  COURSE_COST: 1000000,
  LOCATION_RADIUS: 999,
  SERVICE_NAME: 20,
  EMAIL: 100,
  AADHAR_LENGTH: 12,
  CROP_PRICE: 4,
  COMPELETED_AREA: 4,

}
export {
  SERVER_URLS,
  LIST_VIEW_TYPES,
  GLOBAL_STYLES,
  IDENTITY_CARD_TYPES,
  AEROMEGH_SERVICES,
  DRONE_CAPACITIES,
  DRONE_UIN,
  DRONE_TYPES,
  LIMITS,
  DRONE_NAMES,
  SERVICE_TYPES,
  STATUS,
  STATUS_MESSAGES,
  DISCOUNT_TYPES
};
